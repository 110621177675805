import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { useEffect, useState } from 'react';

import { removeNonDigits } from 'util/NumberUtil';

import useSnackbarStack, {
  WithoutIconContent,
} from 'component/hook/useSnackbarStack';

import { showDialog } from 'redux/duck/dialogDuck';
import {
  patchParcelRequested,
  postParcelRequested,
  resetAddressForParcel,
} from 'redux/duck/ecgTestList/ecgTestListDuck';
import { PARCEL_STATUS } from 'component/dialog/ManageParcelDialog/ParcelConst';

const FIVE_SECOND = 5 * 1000;
const { CANCELED } = PARCEL_STATUS;

function useManageParcelDialogOnClickButtonHandler({
  ecgTest,
  parcel,
  formData,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueMessage } = useSnackbarStack(
    WithoutIconContent,
    FIVE_SECOND,
    {
      vertical: 'bottom',
      horizontal: 'right',
    },
    {},
    'Slide'
  );

  const { tid } = ecgTest;
  const { id } = parcel;
  const { senderPhoneNumber } = formData;

  const handleShowDialog = (dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  };
  const handleResetAddressForParcel = () => dispatch(resetAddressForParcel());

  const handlePostParcelRequested = (body, callback = Function) => {
    dispatch(postParcelRequested({ body, callback }));
  };
  const handlePatchParcelRequested = (parcelId, body, callback = Function) => {
    dispatch(patchParcelRequested({ parcelId, body, callback }));
  };

  const onClickConfirmButton = () => {
    handlePostParcelRequested(
      {
        ...formData,
        ecgTest: tid,
        senderPhoneNumber: removeNonDigits(senderPhoneNumber),
      },
      () => enqueueMessage('택배 예약이 완료되었습니다.')
    );
  };
  const onClickEditButton = () => {
    handlePatchParcelRequested(
      id,
      {
        ...formData,
        senderPhoneNumber: removeNonDigits(senderPhoneNumber),
      },
      () => enqueueMessage('택배 예약이 수정되었습니다.')
    );
  };
  const onClickCancelParcelButton = () => {
    handleShowDialog('ConfirmDialog', {
      title: '택배 예약을 취소하시겠습니까?',
      message: '취소하시면 재예약이 불가능합니다. 계속 진행하시겠습니까?',
      messageStyle: { color: theme.color.COOL_GRAY_70 },
      confirmButtonText: '예약 취소',
      confirmButtonColor: theme.color.RED_70,
      cancelButtonText: '취소',
      cancelButtonColor: theme.color.COOL_GRAY_80,
      onSubmit: () => {
        handlePatchParcelRequested(id, { status: CANCELED.value }, () =>
          enqueueMessage('택배 예약이 취소되었습니다.')
        );
      },
    });
  };

  return {
    handleResetAddressForParcel,
    onClickConfirmButton,
    onClickEditButton,
    onClickCancelParcelButton,
  };
}

export default useManageParcelDialogOnClickButtonHandler;
