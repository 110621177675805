import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Const from 'constant/Const';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import useKeyEventListener from 'component/hook/useKeyEventListener';

import TableSidePanelFragment from 'component/fragment/main/TableSidePanelFragment';

import { showDialog } from 'redux/duck/dialogDuck';
import {
  deleteEcgTestRequested,
  fetchEcgTestsRequested,
  hideSidePanel,
  patchEcgTestRequested,
  revertStatusRequested,
  selectPatchState,
  selectSidePanel,
  setReportDownloadStatusCheckRequested,
} from 'redux/duck/ecgTestList/ecgTestListDuck';

const { ESCAPE } = Const.KEY_MAP;

function TableSidePanelFragmentContainer() {
  const dispatch = useDispatch();

  // store states
  const sidePanelState = useShallowEqualSelector(selectSidePanel);
  const authUserAccessLevelState = useShallowEqualSelector(
    ({ authReducer }) => {
      if (authReducer.user?.isHospitalAdmin) return 2;
      if (authReducer.user?.accessLevel) return authReducer.user.accessLevel;
      return 1;
    }
  );
  const ecgTestPatchState = useShallowEqualSelector(selectPatchState);
  const validReportPending = useShallowEqualSelector(
    ({ ecgTestListReducer }) => ecgTestListReducer.validReport.pending
  );
  const medicalStaffsFetchPending = useShallowEqualSelector(
    ({ medicalStaffsReducer }) => medicalStaffsReducer.fetch.pending
  );

  // dispatches
  const handleHideSidePanel = () => {
    setIsEditMode(false);
    dispatch(hideSidePanel());
  };
  const handleRevertStatusRequested = ({ ecgTestId, callback }) => {
    dispatch(revertStatusRequested({ ecgTestId, callback }));
  };
  const handleDeleteEcgTestRequested = ({ ecgTestId, callback }) => {
    dispatch(deleteEcgTestRequested({ ecgTestId, callback }));
  };
  const handleShowDialog = ({ dialogKey, params, callback }) => {
    dispatch(showDialog(dialogKey, params, callback));
  };
  const handlePatchEcgTestRequested = ({
    ecgTestId,
    patientInfo,
    callback,
  }) => {
    dispatch(patchEcgTestRequested({ ecgTestId, form: patientInfo, callback }));
  };
  const handleDebounceFetchEcgTests = (param) =>
    dispatch(fetchEcgTestsRequested({ ...param }));

  // 리포트 조회 다운로드 체크
  const handleReportDownloadStatusCheck = (uploadStatus) => {
    dispatch(
      setReportDownloadStatusCheckRequested(sidePanelState.ecgTest.tid, {
        isUploadedToEmr: uploadStatus,
      })
    );
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [isNoteEditMode, setIsNoteEditMode] = useState(false);

  // custom
  useKeyEventListener('keydown', [ESCAPE], (event) => {
    if (!sidePanelState.isOpen) return;

    handleHideSidePanel();
    event.preventDefault();
  });

  const loadPageData = (params) => handleDebounceFetchEcgTests({ ...params });
  const emrUploadedDatetime = sidePanelState.ecgTest?.emrUploadedDatetime;

  return (
    <TableSidePanelFragment
      // store states
      sidePanelState={sidePanelState}
      ecgTestPatchState={ecgTestPatchState}
      authUserAccessLevelState={authUserAccessLevelState}
      validReportPending={validReportPending}
      medicalStaffsFetchPending={medicalStaffsFetchPending}
      // dispatches
      handleHideSidePanel={handleHideSidePanel}
      handleRevertStatusRequested={handleRevertStatusRequested}
      handleDeleteEcgTestRequested={handleDeleteEcgTestRequested}
      handlePatchEcgTestRequested={handlePatchEcgTestRequested}
      handleShowDialog={handleShowDialog}
      loadPageData={loadPageData}
      handleReportDownloadStatusCheck={handleReportDownloadStatusCheck}
      // local state
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      isNoteEditMode={isNoteEditMode}
      setIsNoteEditMode={setIsNoteEditMode}
      emrUploadedDatetime={emrUploadedDatetime}
    />
  );
}

export default TableSidePanelFragmentContainer;
