/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Const, {
  INPUT_TAGNAME_LIST as _INPUT_TAGNAME_LIST,
} from 'constant/Const';

import usePrevious from 'component/hook/usePrevious';
import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import useKeyEventListener from 'component/hook/useKeyEventListener';

import TableListFilterHeaderFragment from 'component/fragment/main/TableListFilterHeaderFragment';

import {
  fetchEcgTestsRequested,
  resetTableFilterOptions,
} from 'redux/duck/ecgTestList/ecgTestListDuck';

const { SLASH } = Const.KEY_MAP;
const INPUT_TAGNAME_LIST = _INPUT_TAGNAME_LIST;

function TableListFilterHeaderFragmentContainer(props) {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  // selector
  const { searchKeyword } = useShallowEqualSelector(
    ({ ecgTestListReducer }) => ecgTestListReducer.fetch.tableFilterOptions
  );
  const selectedStartEndDateTime = useShallowEqualSelector(
    ({ ecgTestListReducer }) =>
      ecgTestListReducer.fetch.tableFilterOptions.selectedStartEndDateTime
  );

  // dispatch
  const handleDebounceFetchEcgTests = (param) =>
    dispatch(fetchEcgTestsRequested({ ...param }));
  const handleResetTableFilterOptions = () => {
    setInputValue('');
    dispatch(resetTableFilterOptions());
  };

  // useState
  const [inputValue, setInputValue] = useState('');

  // useEffect
  // 이전 검색어가 있는 상태에서, 검색어를 모두 지웠을때, 검색어를 초기화
  const previousInputValue = usePrevious(inputValue);
  useEffect(() => {
    if (inputValue.length === 0 && previousInputValue?.length >= 1) {
      handleDebounceFetchEcgTests({
        page: 1,
        searchKeyword: '',
      });
    }
  }, [inputValue]);

  useEffect(() => {
    setInputValue(searchKeyword);
  }, [searchKeyword]);

  // custom
  useKeyEventListener('keydown', [SLASH], (event) => {
    if (INPUT_TAGNAME_LIST.includes(event.target.tagName)) return;

    inputRef.current.getInputRef().focus();
    event.preventDefault();
  });

  return (
    <TableListFilterHeaderFragment
      inputRef={inputRef}
      inputValue={inputValue}
      setInputValue={setInputValue}
      // selector
      searchKeyword={searchKeyword}
      selectedStartEndDateTime={selectedStartEndDateTime}
      // dispatch
      handleDebounceFetchEcgTests={handleDebounceFetchEcgTests}
      handleResetTableFilterOptions={handleResetTableFilterOptions}
    />
  );
}

export default TableListFilterHeaderFragmentContainer;
