import styled from 'styled-components';

import DialogRowItem from 'component/ui/dialog/DialogRowItem';

function Label({ label, height, value }) {
  return (
    <StyledDialogRowItem label={label} height={height}>
      <LabelText>{value}</LabelText>
    </StyledDialogRowItem>
  );
}

export default Label;

const LabelText = styled.div`
  min-height: 32px;
  word-break: break-all;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const StyledDialogRowItem = styled(DialogRowItem)`
  margin: 0 !important;
  padding: 4px 0px;
  box-sizing: border-box;
  height: ${({ height }) => height || '40px'};
  > div {
    color: ${({ theme }) => theme.color.COOL_GRAY_70};
  }
`;
