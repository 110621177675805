import { useEffect, useState } from 'react';

import DateUtil from 'util/DateUtil';

function useStartEndDatePicker({ startDate, endDate, fetchHandler }) {
  const [selectedStartDate, setSelectedStartDate] = useState(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState(undefined);
  const [selectedMonth, setSelectedMonth] = useState(
    startDate?.getMonth() ?? DateUtil.getUserLocationTime().getMonth()
  );
  const [selectedYear, setSelectedYear] = useState(
    startDate?.getFullYear() ?? DateUtil.getUserLocationTime().getFullYear()
  );

  // useEffect
  useEffect(() => {
    if (startDate && endDate) {
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
      setSelectedMonth(endDate.getMonth());
      setSelectedYear(endDate.getFullYear());
      return;
    }

    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    setSelectedMonth(DateUtil.getUserLocationTime().getMonth());
    setSelectedYear(DateUtil.getUserLocationTime().getFullYear());
  }, [startDate, endDate]);

  useEffect(() => {
    if (!selectedEndDate) return;

    fetchHandler({ selectedStartDate, selectedEndDate });
  }, [selectedEndDate]);

  return {
    // State
    selectedStartDate,
    selectedEndDate,
    selectedMonth,
    selectedYear,
    // setState
    setSelectedMonth,
    setSelectedYear,
    // functions
    onMonthChangeHandler,
    onYearChangeHandler,
    onChangeHandler,
    clearClickHandler,
    resetSelectedMonthAndYear,
    totalDaysHandler,
    isSameMonth,
    formatWeekDay,
  };

  // functions
  function onMonthChangeHandler(date) {
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());
  }
  function onYearChangeHandler(date) {
    setSelectedYear(date.getFullYear());
  }
  function onChangeHandler(dates) {
    const [start, end] = dates;

    if (selectedStartDate === selectedEndDate && start > selectedStartDate) {
      setSelectedEndDate(start);
    } else {
      setSelectedStartDate(start);
      setSelectedEndDate(start);
    }

    if (end) {
      setSelectedEndDate(end);
    }
  }
  function clearClickHandler() {
    if (!selectedStartDate && !selectedEndDate) return;

    onChangeHandler([null, null]);
  }
  function resetSelectedMonthAndYear({ setSelectedMonth, setSelectedYear }) {
    setSelectedMonth(
      selectedStartDate?.getMonth() ?? DateUtil.getUserLocationTime().getMonth()
    );
    setSelectedYear(
      selectedStartDate?.getFullYear() ??
        DateUtil.getUserLocationTime().getFullYear()
    );
  }
  function totalDaysHandler({ selectedStartDate, selectedEndDate }) {
    if (!selectedStartDate || !selectedEndDate) return 0;

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const days =
      Math.round((selectedEndDate - selectedStartDate) / millisecondsPerDay) +
      1;

    return days;
  }
  function isSameMonth({ date, selectedMonth }) {
    return date.getMonth() === selectedMonth;
  }
  function formatWeekDay(dayOfWeekLabel) {
    return dayOfWeekLabel.toString().charAt(0);
  }
}

export default useStartEndDatePicker;
