/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

import { tableSortInfo } from 'constant/TestTableCont';
import { TEST_STATUS_ARRAY } from 'constant/Const';
import LocalStorageKey from 'constant/LocalStorageKey';

import {
  getFetchOrderListParam,
  getFilterExceptNoneState,
  isObjectEmpty,
} from 'util/Utility';

import useIsMounted from 'component/hook/useIsMounted';
import usePrevious from 'component/hook/usePrevious';
import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import useSnackbarStack from 'component/hook/useSnackbarStack';

import EcgTestListFragment from 'component/fragment/main/EcgTestListFragment';

import { showDialog } from 'redux/duck/dialogDuck';
import {
  fetchEcgTestsRequested,
  revertStatusRequested,
  showSidePanel,
  hideSidePanel,
} from 'redux/duck/ecgTestList/ecgTestListDuck';
import LocalStorageManager from 'manager/LocalStorageManager';

const QUERY_PARAM = {
  SEARCH_KEYWORD: 'searchKeyword',
  ECG_TEST_STATUS: 'ecgTestStatus',
  PRESCRIPTION_DURATION: 'prescriptionDuration',
  PAGE: 'page',
  ORDER_BY: 'orderBy',
  START_TIMESTAMP_MS_RANGE: 'startTimestampMsRange',
  INTERNAL_CONFIRM_TIMESTAMP_MS_RANGE: 'internalConfirmTimestampMsRange',
};

const CONST_TEST_STATUS_ARRAY = TEST_STATUS_ARRAY;
const { LAST_VIEWED_TID_STATE } = LocalStorageKey;

export const sortTarget = tableSortInfo.sortTarget;
export const initFetchOrderList = tableSortInfo.default;
export const ClassTableContent = '.TableContent';

function EcgTestListFragmentContainer(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { enqueueMessage } = useSnackbarStack();
  const isMounted = useIsMounted();

  // selector
  const {
    fetch: ecgTestsFetchState,
    delete: ecgTestsDeleteState,
    returnDevice: ecgTestReturnDeviceState,
    sidePanel: ecgTestSidePanelState,
    confirm: ecgTestConfirmState,
  } = useShallowEqualSelector(({ ecgTestListReducer }) => ecgTestListReducer);
  const isUseParcel = useShallowEqualSelector(
    ({ authReducer }) => authReducer.user.hospital?.isUseParcel
  );
  const { page, orderBy } = useShallowEqualSelector(
    ({ ecgTestListReducer }) => ecgTestListReducer.fetch.tableFilterOptions
  );
  const reportStatePending = useShallowEqualSelector(
    ({ testResultReducer }) => testResultReducer.reportState.pending
  );
  const fetchPending = useShallowEqualSelector(
    ({ ecgTestListReducer }) => ecgTestListReducer.fetch.pending
  );
  const validReportPending = useShallowEqualSelector(
    ({ ecgTestListReducer }) => ecgTestListReducer.validReport.pending
  );
  const medicalStaffsFetchPending = useShallowEqualSelector(
    ({ medicalStaffsReducer }) => medicalStaffsReducer.fetch.pending
  );

  // dispatch
  const handleDebounceFetchEcgTests = (param) =>
    dispatch(fetchEcgTestsRequested({ ...param }));
  const handleShowDialog = ({ dialogKey, params, callback }) => {
    dispatch(showDialog(dialogKey, params, callback));
  };
  const handleRevertStatusRequested = ({ ecgTestId, callback }) => {
    dispatch(revertStatusRequested({ ecgTestId, callback }));
  };
  const handleShowSidePanel = ({ ecgTestId }) => {
    dispatch(showSidePanel({ ecgTestId }));
  };
  const handleHideSidePanel = () => {
    dispatch(hideSidePanel());
  };

  // Synchronize 'global state' to 'URL state', when OnMount this Comp.
  useSetEcgTestFilterOnMount(handleDebounceFetchEcgTests);

  // useState
  const [fetchOrderList, setFetchOrderList] = useState(() => {
    if (orderBy === '') return initFetchOrderList;

    const orderKey = orderBy.replace('-', '');
    const orderValue = orderBy.includes('-') ? 2 : 1;

    return { ...initFetchOrderList, [orderKey]: orderValue };
  });

  useEffect(() => {
    LocalStorageManager.removeItem(LAST_VIEWED_TID_STATE);
  }, []);

  useEffect(() => {
    // 페이지 넘길 때, Table Scroll to top
    document.querySelector(ClassTableContent).scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    if (Boolean(orderBy)) return;

    setFetchOrderList(initFetchOrderList);
  }, [orderBy]);

  useEffect(() => {
    if (!isMounted) return;

    // 정렬이 변경될때마다 ecg test list를 fetch한다
    let orderingParam = getFilterExceptNoneState(fetchOrderList);
    if (isObjectEmpty(orderingParam)) {
      orderingParam = [];
    }

    handleDebounceFetchEcgTests({
      orderBy: getFetchOrderListParam(orderingParam),
    });
  }, [fetchOrderList]);

  const prevEcgTestsDeleteState = usePrevious(ecgTestsDeleteState);
  useEffect(() => {
    function handleDeleteEcgTestRequestedCallback() {
      if (
        prevEcgTestsDeleteState &&
        prevEcgTestsDeleteState.pending &&
        !ecgTestsDeleteState.pending
      ) {
        if (ecgTestsDeleteState.error) {
        } else {
          handleDebounceFetchEcgTests();
          enqueueMessage(intl.formatMessage(INTL_MAP.ALERT_DIALOG_01));
          handleHideSidePanel();
        }
      }
    }

    handleDeleteEcgTestRequestedCallback();
  }, [ecgTestsDeleteState.pending]);

  const prevEcgTestReturnDeviceState = usePrevious(ecgTestReturnDeviceState);
  useEffect(() => {
    function returnDeviceCallback() {
      if (
        prevEcgTestReturnDeviceState &&
        prevEcgTestReturnDeviceState.pending &&
        !ecgTestReturnDeviceState.pending
      ) {
        if (ecgTestReturnDeviceState.error) {
        } else {
          handleShowDialog({
            dialogKey: 'AlertDialog',
            params: {
              message: intl.formatMessage(INTL_MAP.ALERT_DIALOG_02),
            },
            callback: () => {
              handleDebounceFetchEcgTests();
            },
          });
        }
      }
    }

    returnDeviceCallback();
  }, [ecgTestReturnDeviceState.pending]);

  const prevFetchPending = usePrevious(fetchPending);
  useEffect(() => {
    if (prevFetchPending && !fetchPending && ecgTestSidePanelState.isOpen) {
      handleShowSidePanel({
        ecgTestId: ecgTestSidePanelState.ecgTest.tid,
      });
    }
  }, [fetchPending]);

  const prevConfirmPending = usePrevious(ecgTestConfirmState.pending);
  useEffect(() => {
    // 검토완료, 재검토 시 side panel을 닫는 로직.
    if (prevConfirmPending && !ecgTestConfirmState.pending) {
      handleHideSidePanel();
    }
  }, [ecgTestConfirmState.pending]);

  const prevSidePanelState = usePrevious(ecgTestSidePanelState);
  useEffect(() => {
    // side panel이 열려있는 상태에서, 테이블을 정렬 할 경우, 정렬한 테이블에 현재 보고 있는 sidePanel의 정보가 존재하지 않으면, side panel을 닫는다.
    if (
      prevSidePanelState?.ecgTest !== ecgTestSidePanelState?.ecgTest &&
      !ecgTestSidePanelState.ecgTest
    ) {
      handleHideSidePanel();
    }
  }, [ecgTestSidePanelState]);

  // 리포트 생성시 ecgTest의 reportStatus가 DB에서 변경되는데에 시간이 다소 소요되므로
  // 리포트 렌더링중임을 표시할 수 없는 문제점이 존재합니다.
  // 리포트 생성 요청 시 snackbar로 안내하고, response가 왔을 때 refresh하는 방식으로 처리
  const prevReportStatePending = usePrevious(reportStatePending);
  useEffect(() => {
    // 위,아래 두 if문의 condition이 반대임에 주의해야합니다
    // 리포트 생성 요청시 (prev가 false, current가 true)
    if (!prevReportStatePending && reportStatePending) {
      enqueueMessage(
        intl.formatMessage(INTL_MAP.SNACKBAR_PRINTING_REPORT_REQUESTED)
      );
    }

    // 리포트 생성 완료시 (prev가 true, current가 false)
    if (prevReportStatePending && !reportStatePending) {
      handleDebounceFetchEcgTests();
      if (ecgTestSidePanelState.isOpen) {
        handleShowSidePanel({
          ecgTestId: ecgTestSidePanelState.ecgTest.tid,
        });
      }
    }
  }, [reportStatePending]);

  useEffect(() => {
    const intervalIdForPooling = setInterval(
      handleDebounceFetchEcgTests,
      30000
    );

    return () => clearInterval(intervalIdForPooling);
  }, [page]);

  const HEADER_DATA_LIST = createHeaderDataList(
    isUseParcel,
    fetchOrderList,
    setFetchOrderList,
    intl
  );

  return (
    <EcgTestListFragment
      headerDataList={HEADER_DATA_LIST}
      loadPageData={handleDebounceFetchEcgTests}
      // selector
      ecgTestsFetchState={ecgTestsFetchState}
      ecgTestSidePanelState={ecgTestSidePanelState}
      validReportPending={validReportPending}
      medicalStaffsFetchPending={medicalStaffsFetchPending}
      isUseParcel={isUseParcel}
      // dispatch
      handleShowDialog={handleShowDialog}
      handleShowSidePanel={handleShowSidePanel}
      handleRevertStatusRequested={handleRevertStatusRequested}
    />
  );
}

function useSetEcgTestFilterOnMount(handleDebounceFetchEcgTests) {
  // URL을 토대로 tableFilterOptions 변경
  useEffect(() => {
    if (window.location.pathname !== '/') return;

    const searchParams = new URLSearchParams(window.location.search);

    const unfilteredQueryParams = {
      searchKeyword: _getQueryParam(QUERY_PARAM.SEARCH_KEYWORD),
      ecgTestStatus: _getStatusKeys(
        _getArrayQueryParam(QUERY_PARAM.ECG_TEST_STATUS)
      ),
      prescriptionDuration: _getArrayQueryParam(
        QUERY_PARAM.PRESCRIPTION_DURATION,
        String
      ),
      page: Number(_getQueryParam(QUERY_PARAM.PAGE)),
      orderBy: _getQueryParam(QUERY_PARAM.ORDER_BY),
      selectedStartEndDateTime: _getSelectedStartEndDateTime(),
    };

    const queryParams = Object.entries(unfilteredQueryParams).reduce(
      (acc, [key, value]) => {
        if (value && value?.length !== 0) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    handleDebounceFetchEcgTests(queryParams);

    // inner Function
    function _getQueryParam(paramName) {
      return searchParams.get(paramName);
    }
    function _getArrayQueryParam(paramName, parserFunc = Number) {
      const paramValue = _getQueryParam(paramName);
      return paramValue ? paramValue.split(',').map(parserFunc) : undefined;
    }
    function _getSelectedStartEndDateTime() {
      const startTimestampMsRangeParam = searchParams.get(
        QUERY_PARAM.START_TIMESTAMP_MS_RANGE
      );
      const internalConfirmTimestampMsRangeParam = searchParams.get(
        QUERY_PARAM.INTERNAL_CONFIRM_TIMESTAMP_MS_RANGE
      );

      if (
        !startTimestampMsRangeParam &&
        !internalConfirmTimestampMsRangeParam
      ) {
        return undefined;
      }

      const type = startTimestampMsRangeParam
        ? QUERY_PARAM.START_TIMESTAMP_MS_RANGE
        : QUERY_PARAM.INTERNAL_CONFIRM_TIMESTAMP_MS_RANGE;

      return {
        type,
        startTimestampMsRange: _getArrayQueryParam(
          QUERY_PARAM.START_TIMESTAMP_MS_RANGE,
          (timeStamp) => new Date(Number(timeStamp))
        ),
        internalConfirmTimestampMsRange: _getArrayQueryParam(
          QUERY_PARAM.INTERNAL_CONFIRM_TIMESTAMP_MS_RANGE,
          (timeStamp) => new Date(Number(timeStamp))
        ),
      };
    }
    function _getStatusKeys(statusCodes) {
      if (!statusCodes) return;

      const keySet = new Set();
      for (const statusCode of statusCodes) {
        for (const statusObj of CONST_TEST_STATUS_ARRAY) {
          if (statusObj.value.includes(statusCode)) {
            keySet.add(statusObj.key);
          }
        }
      }
      const keys = Array.from(keySet);
      return keys;
    }
  }, []);
}

function createHeaderDataList(
  isUseParcel,
  fetchOrderList,
  setFetchOrderList,
  intl
) {
  const baseHeaderDataList = [
    {
      label: intl.formatMessage(INTL_MAP.PATIENT_NUMBER),
      sortOption: {
        isSortable: true,
        orderColumn: sortTarget.patientNumber,
        defaultSortType: fetchOrderList[sortTarget.patientNumber],
        setOrderByType: setFetchOrderList,
      },
    },
    {
      label: intl.formatMessage(INTL_MAP.PATIENT_NAME),
      sortOption: {
        isSortable: true,
        orderColumn: sortTarget.patientName,
        defaultSortType: fetchOrderList[sortTarget.patientName],
        setOrderByType: setFetchOrderList,
      },
    },
    {
      label: intl.formatMessage(INTL_MAP.PATIENT_BIRTH),
      sortOption: {
        isSortable: false,
      },
    },
    {
      label: intl.formatMessage(INTL_MAP.DEVICE_NUMBER),
      sortOption: {
        isSortable: true,
        orderColumn: sortTarget.deviceNumber,
        defaultSortType: fetchOrderList[sortTarget.deviceNumber],
        setOrderByType: setFetchOrderList,
      },
    },
    {
      label: intl.formatMessage(INTL_MAP.SHORT_ID),
      sortOption: {
        isSortable: false,
      },
    },
    {
      label: intl.formatMessage(INTL_MAP.ECG_TEST_STATUS),
      sortOption: {
        isSortable: true,
        orderColumn: sortTarget.testStatus,
        defaultSortType: fetchOrderList[sortTarget.testStatus],
        setOrderByType: setFetchOrderList,
      },
    },
    {
      label: intl.formatMessage(INTL_MAP.DATA),
      sortOption: {
        isSortable: false,
      },
    },
    {
      label: intl.formatMessage(INTL_MAP.PRESCRIPTION_DURATION),
      sortOption: {
        isSortable: true,
        orderColumn: sortTarget.prescriptionDuration,
        defaultSortType: fetchOrderList[sortTarget.prescriptionDuration],
        setOrderByType: setFetchOrderList,
      },
    },
    {
      label: intl.formatMessage(INTL_MAP.START_DATETIME),
      sortOption: {
        isSortable: true,
        orderColumn: sortTarget.startDatetime,
        defaultSortType: fetchOrderList[sortTarget.startDatetime],
        setOrderByType: setFetchOrderList,
      },
    },
    {
      label: intl.formatMessage(INTL_MAP.ESTIMATED_END_DATETIME),
      sortOption: {
        isSortable: true,
        orderColumn: sortTarget.endDatetime,
        defaultSortType: fetchOrderList[sortTarget.endDatetime],
        setOrderByType: setFetchOrderList,
      },
    },
  ];

  if (isUseParcel) {
    baseHeaderDataList.unshift({
      label: '택배 신청',
      sortOption: {
        isSortable: false,
      },
    });
  }

  return baseHeaderDataList;
}

/**
 * - ALERT_DIALOG_01: 검사가 삭제되었습니다.
 * - ALERT_DIALOG_02: 반납처리가 완료되었습니다.
 * - PATIENT_NUMBER: 환자번호
 * - PATIENT_NAME: 환자명
 * - PATIENT_BIRTH: 생년월일
 * - DEVICE_NUMBER: 패치 일련번호
 * - SHORT_ID: 검사번호
 * - ECG_TEST_STATUS: 상태
 * - DATA: 데이터
 * - PRESCRIPTION_DURATION: 처방기간
 * - START_DATETIME: 검사 시작일
 * - ESTIMATED_END_DATETIME: 검사 종료일
 * - SNACKBAR_PRINTING_REPORT_REQUESTED: 리포트 생성이 요청되었습니다. 시간이 다소 소요됩니다.
 */
const INTL_MAP = defineMessages({
  ALERT_DIALOG_01: {
    id: '99-EcgTestListFragment-AlertDialog-01',
    description: '3.4 검사 추가 팝업에서 삭제 성공 결과 Alert 메시지',
    defaultMessage: '검사가 삭제되었습니다.',
  },
  ALERT_DIALOG_02: {
    id: '99-OnGoingTestFragment-AlertDialog-02',
    description: '반납처리가 완료되었습니다.',
    defaultMessage: '반납처리가 완료되었습니다.',
  },
  PATIENT_NUMBER: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-patientNumber',
    description: '환자번호',
    defaultMessage: '환자번호',
  },
  PATIENT_NAME: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-patientName',
    description: '환자명',
    defaultMessage: '환자명',
  },
  PATIENT_BIRTH: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-patientBirth',
    description: '생년월일',
    defaultMessage: '생년월일',
  },
  DEVICE_NUMBER: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-deviceNumber',
    description: '패치 일련번호',
    defaultMessage: '패치 일련번호',
  },
  SHORT_ID: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-shortId',
    description: '검사번호',
    defaultMessage: '검사번호',
  },
  ECG_TEST_STATUS: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-ecgTestStatus',
    description: '상태',
    defaultMessage: '상태',
  },
  DATA: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-data',
    description: '데이터',
    defaultMessage: '데이터',
  },
  PRESCRIPTION_DURATION: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-prescriptionDuration',
    description: '처방일수 / Test Duration',
    defaultMessage: '처방일수',
  },
  START_DATETIME: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-startDatetime',
    description: '검사 시작일',
    defaultMessage: '검사 시작일',
  },
  ESTIMATED_END_DATETIME: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-estimatedEndDatetime',
    description: '검사 종료일',
    defaultMessage: '검사 종료일',
  },
  SNACKBAR_PRINTING_REPORT_REQUESTED: {
    id: '99-Snackbar-EcgTestListFragment-printingReportRequested-01',
    description: '리포트 생성이 요청되었습니다. 시간이 다소 소요됩니다.',
    defaultMessage: '리포트 생성이 요청되었습니다. 시간이 다소 소요됩니다.',
  },
});

export default EcgTestListFragmentContainer;
