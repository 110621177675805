import styled from 'styled-components';
import { Dispatch, forwardRef, ReactNode, Ref, SetStateAction } from 'react';

import TextInput from 'component/ui/input/TextInput';

interface ContentProps {
  addressInput: string;
  setAddressInput: Dispatch<SetStateAction<string>>;
  endIcon?: ReactNode;
  onClickEndIcon: () => void;
  onTextInputSubmit: ({ page, addressInput }) => void;
}

function SearchInput(
  props: ContentProps,
  ref: Ref<HTMLInputElement>
): JSX.Element {
  const {
    addressInput,
    setAddressInput,
    endIcon,
    onClickEndIcon,
    onTextInputSubmit,
  } = props;

  return (
    <DialogContentContainer>
      <Wrapper>
        <InputWrapper>
          <StyledTextInput
            tabIndex={0}
            placeholder="도로명,건물명, 주소 검색"
            ref={ref}
            value={addressInput}
            onChange={(text) => {
              setAddressInput(text);
            }}
            onSubmit={() => {
              onTextInputSubmit({ page: 1, addressInput });
            }}
          />
          {endIcon && (
            <EndIconWrapper onClick={onClickEndIcon}>{endIcon}</EndIconWrapper>
          )}
        </InputWrapper>
      </Wrapper>
    </DialogContentContainer>
  );
}
const DialogContentContainer = styled.div`
  height: 40px;
  margin: 2px 20px 0px 0px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.color.COOL_GRAY_40}`};
  padding-right: 40px;

  input {
    color: ${({ theme }) => theme.color.COOL_GRAY_90};
  }
`;

const EndIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.color.COOL_GRAY_70};
  }
`;

export default forwardRef(SearchInput);
