import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useRef } from 'react';

import { DATA_TEST_ID } from 'constant/DataTestId';

import { ResetIcon } from 'component/ui/icons';
import StartEndDatePicker from 'component/ui/picker/StartEndDate/StartEndDatePicker';
import TextInput from 'component/ui/input/TextInput';
import StartEndDatePickerCustomInput from 'component/ui/picker/StartEndDate/StartEndDatePickerCustomInput';

import { ReactComponent as SearchIcon } from 'static/icon/icon-search.svg';
import { ReactComponent as CloseIcon } from 'static/icon/ic-close.svg';

import PrescriptionFilterDropdown from './filterDropdown/PrescriptionFilterDropdown';
import EcgTestStatusFilterDropdown from './filterDropdown/EcgTestStatusFilterDropdown';
import CalendarContainer from './CalendarContainer';

function TableListFilterHeaderFragment({
  inputRef,
  inputValue,
  setInputValue,
  // global State
  searchKeyword,
  selectedStartEndDateTime,
  // dispatch
  handleDebounceFetchEcgTests,
  handleResetTableFilterOptions,
}) {
  const intl = useIntl();
  const datePickerRef = useRef();

  const { type, startTimestampMsRange, internalConfirmTimestampMsRange } =
    selectedStartEndDateTime;
  const [startDate, endDate] = selectedStartEndDateTime[type];

  const searchInputEndIconHandle = {
    endIcon:
      inputValue.length > 0 ? <CloseIconWrapper /> : <SearchIconWrapper />,
    onClickEndIcon: () => {
      inputValue.length > 0
        ? setInputValue('')
        : inputRef.current.getInputRef().focus();
    },
  };

  function onTextInputSubmit() {
    if (typeof inputValue !== 'string') return;
    handleDebounceFetchEcgTests({
      page: 1,
      searchKeyword: inputValue,
    });
  }
  function startEndDatePickerFetchHandler({
    selectedStartDate,
    selectedEndDate,
  }) {
    handleDebounceFetchEcgTests({
      selectedStartEndDateTime: {
        type,
        startTimestampMsRange,
        internalConfirmTimestampMsRange,
        [type]: [selectedStartDate, selectedEndDate],
      },
    });
  }
  function onTypeChange(type) {
    handleDebounceFetchEcgTests({
      selectedStartEndDateTime: {
        type,
        startTimestampMsRange,
        internalConfirmTimestampMsRange,
        [type]: startDate ? [startDate, endDate] : [],
      },
    });
  }
  function clearSelectedStartEndDateTime(type) {
    datePickerRef.current.clearClickHandler();
    handleDebounceFetchEcgTests({
      selectedStartEndDateTime: {
        type,
        startTimestampMsRange: [],
        internalConfirmTimestampMsRange: [],
      },
    });
  }

  return (
    <TableListFilterHeaderFragmentWrapper>
      <Wrapper>
        <TextInput
          dataTestId={DATA_TEST_ID.TABLE_LIST_FILTER_HEADER.SEARCH_INPUT}
          style={{ width: '260px' }}
          inputType="text"
          ref={inputRef}
          value={inputValue}
          placeholder={intl.formatMessage(INTL_MAP.PLACE_HOLDER_01)}
          endIcon={searchInputEndIconHandle.endIcon}
          onClickEndIcon={searchInputEndIconHandle.onClickEndIcon}
          onBlur={() => setInputValue(searchKeyword)}
          onChange={setInputValue}
          onSubmit={onTextInputSubmit}
        />
        <EcgTestStatusFilterDropdown />
        <PrescriptionFilterDropdown />
        <StartEndDatePicker
          dataTestId={
            DATA_TEST_ID.TABLE_LIST_FILTER_HEADER.START_END_DATE_PICKER
          }
          ref={datePickerRef}
          startDate={startDate}
          endDate={endDate}
          CustomInput={(props) => (
            <StartEndDatePickerCustomInput type={type} {...props} />
          )}
          fetchHandler={startEndDatePickerFetchHandler}
          CalendarContainer={(props) => (
            <CalendarContainer
              selectedStartEndDateTime={selectedStartEndDateTime}
              onTypeChange={onTypeChange}
              clearSelectedStartEndDateTime={clearSelectedStartEndDateTime}
              {...props}
            />
          )}
        />
        <ResetButton onClick={handleResetTableFilterOptions} />
      </Wrapper>
    </TableListFilterHeaderFragmentWrapper>
  );
}

export function ResetButton({ onClick }) {
  const intl = useIntl();

  return (
    <ClearDateWrapper
      onClick={onClick}
      data-testid={DATA_TEST_ID.TABLE_LIST_FILTER_HEADER.BUTTON.RESET}>
      <ResetIconWrapper />
      <ClearDateText>{intl.formatMessage(INTL_MAP.RESET_BUTTON)}</ClearDateText>
    </ClearDateWrapper>
  );
}

const TableListFilterHeaderFragmentWrapper = styled.div`
  user-select: none; // drag 방지
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 32px; // 56 - 12 -12
  padding: 12px 20px;
  position: sticky;
  top: 90px;
  left: 0;
  right: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
  @media screen and (min-width: 1920px) {
    width: 80%;
  }
  z-index: 2;
`;

const SearchIconWrapper = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`;

const CloseIconWrapper = styled(CloseIcon)`
  width: 20px;
  height: 20px;
`;

const ResetIconWrapper = styled(ResetIcon)`
  width: 16px;
  height: 16px;
`;

const ClearDateWrapper = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  width: 58px;
  height: 20px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
`;

const ClearDateText = styled.div`
  text-decoration-line: underline;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

const INTL_MAP = defineMessages({
  PLACE_HOLDER_01: {
    id: '02-MainPage-TextInput-placeholder-01',
    description: '환자명, 환자번호, 패치 일련번호, 검사번호',
    defaultMessage: '환자명, 환자번호, 패치 일련번호, 검사번호',
  },
  RESET_BUTTON: {
    id: '02-MainPage-TableListFilterHeaderFragment-ResetButton',
    description: '초기화',
    defaultMessage: '초기화',
  },
});

export default TableListFilterHeaderFragment;
