import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { fetchEcgTestsRequested } from 'redux/duck/ecgTestList/ecgTestListDuck';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

function useFilterDropdown() {
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const tableFilterOptions = useShallowEqualSelector(
    ({ ecgTestListReducer }) => ecgTestListReducer.fetch.tableFilterOptions
  );

  // dispatch
  const handleFetchEcgTestsRequested = (param) =>
    dispatch(fetchEcgTestsRequested({ ...param }));

  return {
    intl,
    tableFilterOptions,
    handleFetchEcgTestsRequested,
  };
}

export default useFilterDropdown;
