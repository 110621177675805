import { defineMessages } from 'react-intl';

import { DATA_TEST_ID } from 'constant/DataTestId';

import useFilterDropdown from 'component/hook/useFilterDropdown';

import DropdownBase from 'component/ui/dropdown/DropdownBase';
import { MenuBase } from 'component/ui/menu/Menu';
import MenuItem from 'component/ui/menu/MenuItem';
import CheckBox from 'component/ui/checkbox/CheckBox';

import {
  LabelText,
  LabelWrapper,
  RenderClearSelection,
  ValueText,
  ValueWrapper,
  GenerateSelectedItemsText,
  StyledDropDownIcon,
  DropdownBaseWrapper,
} from './EcgTestStatusFilterDropdown';

const prescriptionDateArray = _createPaddedArray({ start: 1, end: 14 });

function PrescriptionFilterDropdown() {
  const {
    intl,
    tableFilterOptions: { prescriptionDuration },
    handleFetchEcgTestsRequested,
  } = useFilterDropdown();

  return (
    <DropdownBaseWrapper
      data-testid={DATA_TEST_ID.TABLE_LIST_FILTER_HEADER.PRESCRIPTION_FILTER}>
      <DropdownBase
        anchorProps={{
          style: { padding: '8px', minHeight: '16px', height: '32px' },
        }}
        contentCloseReason="clickAway"
        dropdownDirection="left"
        label={
          <LabelWrapper>
            <LabelText>
              {intl.formatMessage(INTL_MAP.PRESCRIPTION_DURATION)}
            </LabelText>
          </LabelWrapper>
        }
        value={
          <ValueWrapper>
            <ValueText>
              <GenerateSelectedItemsText
                selectedItemList={prescriptionDuration.map(
                  (day) =>
                    day.replace(/^0(\d)$/, '$1') +
                    intl.formatMessage(INTL_MAP.DAY)
                )}
              />
            </ValueText>
            <StyledDropDownIcon />
          </ValueWrapper>
        }>
        <MenuBase style={{ width: 140 }}>
          {prescriptionDateArray.map((day, index) => {
            return (
              <MenuItem
                key={index}
                title={day}
                onClick={() => {
                  if (prescriptionDuration.includes(day)) {
                    handleFetchEcgTestsRequested({
                      page: 1,
                      prescriptionDuration: prescriptionDuration.filter(
                        (selectedDay) => selectedDay !== day
                      ),
                    });
                  } else {
                    handleFetchEcgTestsRequested({
                      page: 1,
                      prescriptionDuration: _sortDays(
                        [...prescriptionDuration, day],
                        prescriptionDateArray
                      ),
                    });
                  }
                }}>
                <CheckBox
                  checked={prescriptionDuration.includes(day)}
                  label={day}
                  labelStyle={{ paddingLeft: 7 }}
                />
              </MenuItem>
            );
          })}
          <RenderClearSelection
            show={prescriptionDuration.length > 1}
            onClick={_clearSelectionHandler}
          />
        </MenuBase>
      </DropdownBase>
    </DropdownBaseWrapper>
  );
  function _sortDays(prescriptionDuration, day) {
    return prescriptionDuration.sort((a, b) => day.indexOf(a) - day.indexOf(b));
  }
  function _clearSelectionHandler() {
    handleFetchEcgTestsRequested({
      page: 1,
      prescriptionDuration: [],
    });
  }
}

export default PrescriptionFilterDropdown;

// inner functions
function _createPaddedArray({ start, end }) {
  return Array.from({ length: end - start + 1 }, (_, i) =>
    String(i + start).padStart(2, '0')
  );
}

/**
 * - PRESCRIPTION_DURATION: 처방 기간
 * - DAY: 일
 */
const INTL_MAP = defineMessages({
  PRESCRIPTION_DURATION: {
    id: '09-GeneralFragment-SectionVerticalItem-label-prescriptionDuration',
    description: '처방일수 / Test Duration',
    defaultMessage: '처방일수',
  },
  DAY: {
    id: '09-GeneralFragment-SectionVerticalItem-value-days',
    description: '처방 기간',
    defaultMessage: '일',
  },
});
