import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { handleSaveFile } from 'util/FileUtil';

import StatusCode from 'network/StatusCode';
import ErrorMessages from 'network/ErrorMessages';

import { showDialog } from 'redux/duck/dialogDuck';
import {
  getEcgTestMedicalFeeExcelDownloadRequested,
  selectTableFilterOptions,
  selectEcgTestDownloadState,
} from 'redux/duck/ecgTestList/ecgTestListDuck';
import { ITheme } from 'theme/StyledComponentsTheme';

import usePrevious from './usePrevious';

function useMedicalFeeExcelDownload() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme() as ITheme;

  const { totalCount } = useSelector(selectTableFilterOptions, shallowEqual);
  const { pending, error } = useSelector(
    selectEcgTestDownloadState,
    shallowEqual
  );

  const handelErrorDialog = () => {
    dispatch(
      showDialog('AlertDialog', {
        title: intl.formatMessage(INTL_MAP.ERROR_DIALOG_TITLE),
        message: intl.formatMessage(INTL_MAP.ERROR_DIALOG_MESSAGE),
        messageStyle: { color: theme.color.COOL_GRAY_70 },
        confirmButtonText: intl.formatMessage(INTL_MAP.ERROR_DIALOG_CONFIRM),
      })
    );
  };

  const progressCallback = (percentCompleted: number) => {
    //
  };
  const callback = async (status: number, data: any, headers: any) => {
    if (status !== StatusCode.OK) {
      handelErrorDialog();
    } else {
      await handleSaveFile(data, headers);
    }
  };

  const handelEcgTestListExcelDownload = () => {
    if (typeof totalCount !== 'number' || totalCount === 0) {
      handelErrorDialog();
      return;
    }

    dispatch(
      getEcgTestMedicalFeeExcelDownloadRequested(progressCallback, callback)
    );
  };

  const prevPending = usePrevious(pending);
  useEffect(() => {
    if (!(prevPending && !pending && error)) {
      return;
    }

    if (
      error.status === StatusCode.BAD_REQUEST &&
      error.errorMessage.includes(ErrorMessages.EXCEL_DOWNLOAD_NO_DATA)
    ) {
      handelErrorDialog();
    }
  }, [pending]);

  return {
    downloadButtonText: intl.formatMessage(INTL_MAP.BUTTON_TEXT),
    downloadButtonTooltip: intl.formatMessage(INTL_MAP.BUTTON_TOOLTIP),
    handelEcgTestListExcelDownload,
    downloadPending: pending,
  };
}

const INTL_MAP = defineMessages({
  BUTTON_TEXT: {
    id: '99-useMedicalFeeExcelDownload-downloadButton-text',
    description: '수가 청구 다운로드 / Download Billing File',
    defaultMessage: '수가 청구 다운로드',
  },
  BUTTON_TOOLTIP: {
    id: '99-useMedicalFeeExcelDownload-downloadButton-Tooltip-title',
    description:
      '필터한 검사의 수가 청구 정보를 엑셀 파일로 다운로드합니다. / Download an Excel file containing the test information',
    defaultMessage:
      '필터한 검사의 수가 청구 정보를 엑셀 파일로 다운로드합니다.',
  },
  ERROR_DIALOG_TITLE: {
    id: '99-useMedicalFeeExcelDownload-errorDialog-title',
    description:
      '필터를 재설정해주세요. / Please review the list filter and try again',
    defaultMessage: '필터를 재설정해주세요.',
  },
  ERROR_DIALOG_MESSAGE: {
    id: '99-useMedicalFeeExcelDownload-errorDialog-message',
    description:
      '다운로드 가능한 수가 청구가 없습니다. \n[시작 전] 상태의 검사는 수가 청구 정보에 포함되지 않습니다. / Tests with the "Not Started" status cannot be included in the file. \nUnable to Download Billing File.',
    defaultMessage:
      '다운로드 가능한 수가 청구가 없습니다. \n[시작 전] 상태의 검사는 수가 청구 정보에 포함되지 않습니다.',
  },
  ERROR_DIALOG_CONFIRM: {
    id: '99-useMedicalFeeExcelDownload-errorDialog-confirm',
    description: '확인 / OK',
    defaultMessage: '확인',
  },
});

export default useMedicalFeeExcelDownload;
