import { css } from '@emotion/react';
import BarLoader from 'react-spinners/BarLoader';
import styled from 'styled-components';

import AppColors from 'theme/AppColors';

const override = css`
  display: block;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  position: absolute;
`;

function BarProgress(props) {
  return (
    <Wrapper style={props.style}>
      <BarLoader
        color={props.color || AppColors.PRIMARY}
        loading={true}
        css={override}
        width={props.width || 160}
        height={props.height || 8}
      />
    </Wrapper>
  );
}

export default BarProgress;
