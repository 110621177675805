import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import DateUtil from 'util/DateUtil';

import useStartEndDatePicker from 'component/hook/useStartEndDatePicker';

import StartEndDatePickerCustomHeader from './StartEndDatePickerCustomHeader';

const GRAY_DAY = 'gray-day';
const HOVERED_DATE = 'hovered-date';
const IN_RANGE = 'in-range';
const AFTER_SELECTED_DATE = 'after-selected-date';

function StartEndDatePicker(
  {
    startDate,
    endDate,
    fetchHandler,
    CalendarContainer,
    CustomInput,
    CustomHeader,
    dataTestId,
  },
  ref
) {
  const {
    // State
    selectedStartDate,
    selectedEndDate,
    selectedMonth,
    selectedYear,
    // setState
    setSelectedMonth,
    setSelectedYear,
    // functions
    onMonthChangeHandler,
    onYearChangeHandler,
    onChangeHandler,
    clearClickHandler,
    resetSelectedMonthAndYear,
    totalDaysHandler,
    isSameMonth,
    formatWeekDay,
  } = useStartEndDatePicker({ startDate, endDate, fetchHandler });

  const changeMonthRef = useRef(null);
  const changeYearRef = useRef(null);

  useImperativeHandle(ref, () => ({
    clearClickHandler,
  }));

  // useState
  const [hoveredDate, setHoveredDate] = useState(null);

  // etc
  const totalDays = totalDaysHandler({
    selectedStartDate,
    selectedEndDate,
  });

  // functions
  const onDayMouseEnter = (date) => {
    setHoveredDate(date);
  };
  const onMouseLeave = () => {
    setHoveredDate(null);
  };
  const dayClassName = (date) => {
    if (!isSameMonth({ date, selectedMonth })) {
      return GRAY_DAY;
    }
    if (DateUtil.getUserLocationTime().getTime() < date.getTime()) {
      return GRAY_DAY;
    }

    if (!selectedStartDate || !hoveredDate) return;

    if (
      selectedStartDate.getTime() === selectedEndDate.getTime() &&
      selectedStartDate.getTime() < hoveredDate.getTime() &&
      selectedStartDate.getTime() === date.getTime()
    ) {
      return AFTER_SELECTED_DATE;
    }

    if (
      selectedStartDate.getTime() !== selectedEndDate.getTime() ||
      selectedStartDate.getTime() > hoveredDate.getTime()
    ) {
      return;
    }

    if (hoveredDate && date.getTime() === hoveredDate.getTime()) {
      return HOVERED_DATE;
    }

    if (isInRange(date)) {
      return IN_RANGE;
    }

    function isInRange(date) {
      if (!selectedStartDate || !hoveredDate) return false;

      const start = Date.parse(selectedStartDate);
      const end = Date.parse(hoveredDate);
      const day = Date.parse(date);
      return (day >= start && day <= end) || (day <= start && day >= end);
    }
  };

  return (
    <Wrapper onMouseLeave={onMouseLeave} data-testid={dataTestId}>
      <ReactDatePicker
        customInputRef={ref}
        customInput={CustomInput && <CustomInput />}
        dateFormat="yyyy.MM.dd" // 날짜 형식
        popperPlacement="top-start" // 달력이 표시되는 위치
        startDate={selectedStartDate} // 시작 날짜
        endDate={selectedEndDate} // 끝 날짜
        minDate={null} // 날짜 선택한 상태로, 다른 달로 넘어갈 시 기존에 선택한 날짜가 보이지 않도록 설정
        selected={null} // value 값이 있으면, 해당 날짜가 선택된 상태로 표시
        onChange={onChangeHandler} // 날짜 선택 시
        formatWeekDay={formatWeekDay} // 요일 한글자로 표시
        renderCustomHeader={({
          changeMonth,
          changeYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => {
          changeMonthRef.current = changeMonth;
          changeYearRef.current = changeYear;
          return (
            <StartEndDatePickerCustomHeader
              /* standard props */
              changeYear={changeYear} // 년도 변경
              decreaseMonth={decreaseMonth} // 이전 달로 이동
              increaseMonth={increaseMonth} // 다음 달로 이동
              prevMonthButtonDisabled={prevMonthButtonDisabled} // 이전 달 버튼 비활성화
              nextMonthButtonDisabled={nextMonthButtonDisabled} // 다음 달 버튼 비활성화
              /* custom functions and variables */
              selectedMonth={selectedMonth} // 선택한 달
              selectedYear={selectedYear} // 선택한 년도
            >
              {CustomHeader && <CustomHeader totalDays={totalDays} />}
            </StartEndDatePickerCustomHeader>
          );
        }} // CustomHeader 컴포넌트 사용
        selectsRange // 날짜 범위 선택
        shouldCloseOnSelect={false} // 날짜 선택 후, 달력 닫히지 않도록 설정
        dayClassName={dayClassName} // 다른 달 날짜는 회색으로 표시
        onMonthChange={onMonthChangeHandler} // 달이 바뀔 때마다 month state 변경
        onYearChange={onYearChangeHandler} // 년이 바뀔 때마다 year state 변경
        onClickOutside={() =>
          resetSelectedMonthAndYear({
            setSelectedMonth,
            setSelectedYear,
          })
        } // 달력 닫힐 때, 날짜 선택 초기화
        disabledKeyboardNavigation // 키보드로 날짜 선택 불가
        fixedHeight // 달력 높이 고정
        onDayMouseEnter={onDayMouseEnter} // 날짜 hover 시
        calendarContainer={(props) => (
          <CalendarContainer
            totalDays={totalDays}
            onChangeHandler={onChangeHandler}
            changeMonth={changeMonthRef.current}
            changeYear={changeYearRef.current}
            {...props}
          />
        )}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  user-select: none;

  .react-datepicker {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14);
  }
  .react-datepicker-popper {
    z-index: 3;
  }
  .react-datepicker__header {
    padding: 0;
  }
  .react-datepicker__day-names {
    margin: 0 0.4rem;
  }
  .react-datepicker__month {
    margin: 0 0 8px 0;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    pointer-events: none;
  }
  // 날짜의 폰트 크기, 폰트 두께, 너비, 높이
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 32px;
    height: 32px;
    line-height: 250%;
    margin: 0;
    font-size: 12px;
  }
  .react-datepicker__day-name {
    font-weight: 500;
  }
  .react-datepicker__day {
    font-weight: 400;
  }

  // 선택된 날짜의 배경색
  .react-datepicker__day--in-range {
    border-radius: unset;
    background-color: ${(props) => props.theme.color.BLUE_30} !important;
    color: ${(props) => props.theme.color.COOL_GRAY_90};
    :hover {
      border-radius: unset;
    }
  }
  .${IN_RANGE} {
    background-color: ${(props) => props.theme.color.BLUE_30} !important;
    color: ${(props) => props.theme.color.COOL_GRAY_90};
  }
  .hovered-date:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end) {
    border-radius: 0% 100% 100% 0% !important;
    background: radial-gradient(
      circle 16.5px,
      ${(props) => props.theme.color.BLUE_30} 94%,
      ${(props) => props.theme.color.BLUE_30}
    ) !important;
  }
  // 시작일의 테두리 둥글게
  .react-datepicker__day--range-start {
    font-weight: 500;
    border-radius: 50% 0% 0% 50%;
  }
  // 종료일의 테두리 둥글게
  .react-datepicker__day--range-end {
    font-weight: 500;
    border-radius: 0% 100% 100% 0%;
  }
  // 선택된 날짜의 배경색 반원으로 표시
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background: radial-gradient(
      circle 16.5px,
      ${(props) => props.theme.color.BLUE_70} 94%,
      ${(props) => props.theme.color.BLUE_30}
    ) !important;
    color: white;
  }
  // 시작일과 종료일이 같을 때 테두리 둥글게
  .react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range {
    border-radius: 16px;
  }
  .react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range.${AFTER_SELECTED_DATE} {
    border-radius: 50% 0% 0% 50%;
  }
  // hover시 테두리 둥글게
  .react-datepicker__day:hover:not(.react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range) {
    border-radius: 16px;
    color: ${(props) => props.theme.color.COOL_GRAY_90};
  }
  .${GRAY_DAY} {
    color: ${(props) => props.theme.color.COOL_GRAY_50};
    pointer-events: none;
  }
`;

export default forwardRef(StartEndDatePicker);
