import styled from 'styled-components';

import DialogRowItem from 'component/ui/dialog/DialogRowItem';
import TextInput from 'component/ui/input/TextInput';

import Label from './Label';

function FormInput({ label, value, placeholder, isEditable, onChange }) {
  if (!isEditable) {
    return <Label label={label} value={value} />;
  }

  return (
    <StyledDialogRowItem label={label} required>
      <StyledTextInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        inputType="text"
      />
    </StyledDialogRowItem>
  );
}

export default FormInput;

export const StyledDialogRowItem = styled(DialogRowItem)`
  margin: 0 !important;
  padding: 4px 0px;
  box-sizing: border-box;
  height: ${({ height }) => height || '40px'};
  > div {
    color: ${({ theme }) => theme.color.COOL_GRAY_70};
  }
`;
export const StyledTextInput = styled(TextInput)`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.color.COOL_GRAY_40}`};
  input {
    color: ${({ theme }) => theme.color.COOL_GRAY_90};
  }
`;
