import { useState, useEffect } from 'react';
import styled from 'styled-components';

import Const from 'constant/Const';
import { sortTarget } from 'constant/TestTableCont';

import { TableHeader, TableHead, TableRow } from 'component/ui/table/Table';
import {
  SortUpwardIcon,
  SortDownwardIcon,
  SortDefaultIcon,
} from 'component/ui/icons';

const TableHeaderContainer = styled.div`
  padding-left: 16px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  width: 20px;
  height: 20px;

  & svg {
    width: 20px;
    height: 20px;
  }

  & > * {
    font-size: 20px;
    color: ${(props) => props.theme.color.DARK};
  }

  :hover {
    border-radius: 3px;
    background-color: ${(props) => props.theme.color.COOL_GRAY_30};
  }
`;

const { ORDER_TYPE } = Const;

/**
 * @typedef SortableTableHeadProps
 * @prop {*} children Head 에 표현될 Column 명 또는 이를 포함한 JSX Node
 * @prop {boolean} isSortable Column 의 Field 를 기준으로 정렬 가능 여부
 * @prop {number} orderType 정렬 방향, Const.ORDER_TYPE 기준 ASC: 1, DESC: 2, 정렬 기준 없음: 0
 * @prop {function} onClickHeader Header 의 Column Click Event Handler
 * @prop {string} orderColumn [DEPRECATED] 정렬 기준이 되는 Field 명, API 요청 Parameter 에 동일한 값 사용
 * @prop {boolean} isSelected [DUPLICATED] isSortable Property 가 True 일 경우 할당되는 값 동일
 */
/**
 *
 * @param {SortableTableHeadProps} props
 * @returns
 */
function SortableTableHead(props) {
  const {
    children,
    isSortable,
    orderType,
    onClickHeader,
    //
    orderColumn,
    isSelected,
  } = props;

  const renderArrowIcon = () => {
    // 정렬 icon 설정
    if (orderType === ORDER_TYPE.ASCENDING) return <SortUpwardIcon />;
    if (orderType === ORDER_TYPE.DESCENDING) return <SortDownwardIcon />;

    return <SortDefaultIcon />;
  };

  return (
    <TableHead
      isSortable={isSortable}
      isSelected={isSelected}
      orderType={orderType}
      onClick={onClickHeader}>
      <TableHeaderContainer>
        {children}
        {isSelected && <IconWrapper>{renderArrowIcon()}</IconWrapper>}
      </TableHeaderContainer>
    </TableHead>
  );
}

const StyledTableRow = styled(TableRow)`
  background-color: transparent;
  :hover {
    background-color: transparent;
  }
`;

/**
 * @typedef SortOption
 * @prop {boolean} isSortable Column 의 Field 를 기준으로 정렬 가능 여부
 * @prop {string} [orderColumn] 정렬 기준이 되는 Field 명, API 요청 Parameter 에 동일한 값 사용
 * @prop {number} [defaultSortType] 정렬 방향, Const.ORDER_TYPE 기준 ASC: 1, DESC: 2, 정렬 기준 없음: 0
 * @prop {function} [setOrderByType] 정렬 기준과 정렬 방향 상태 변경 Handler
 * @prop {boolean} [isDescFirst] 정렬 방향 변경 순서 변경 여부, True 면 NONE -> DESC -> ASC -> NONE 으로 변경됨, 그렇지 않으면, NONE -> ASC -> DESC -> NONE 로 변경
 *
 * @typedef SortableColumn
 * @prop {*} label Head 에 표현될 Column 명 또는 이를 포함한 JSX Node
 * @prop {SortOption} sortOption 정렬 관련 옵션
 *
 * @typedef SortableTableHeaderProps
 * @prop {{}} [style] `<TableHeader>` 의 inline style sheet
 * @prop {Array<SortableColumn>} headerDataList
 * @prop {Array<string>} [headerTitles] [WILL DEPRECATE] 사용자 목록에서 사용하는 테이블 Column 정보 목록
 * @prop {boolean} [isSortable] [WILL DEPRECATE] 사용되는곳 없음
 */
/**
 *
 * @param {SortableTableHeaderProps} props
 * @returns
 */
function SortableTableHeader(props) {
  const {
    style,
    headerDataList: _headerDataList = [],
    //
    headerTitles,
    isSortable = false,
  } = props;

  const [selectedColumnIndex, setSelectedColumnIndex] = useState();
  const [headerDataList, setHeaderDataList] = useState(_headerDataList);

  useEffect(() => {
    if (_headerDataList.length) setHeaderDataList(_headerDataList);
  }, [_headerDataList]);

  return (
    <TableHeader style={style}>
      <StyledTableRow className="TableHeaderRow">
        {headerDataList.length >= 0 &&
          headerDataList.map((headerData, index) => {
            const {
              label,
              sortOption: {
                isSortable,
                defaultSortType,
                isDescFirst,
                //
                orderColumn,
                setOrderByType,
              },
            } = headerData;

            return (
              <SortableTableHead
                key={label}
                isSortable={isSortable}
                orderType={defaultSortType}
                onClickHeader={() => {
                  isSortable &&
                    _onClickHeader({
                      index,
                      orderColumn,
                      setOrderByType,
                      defaultSortType,
                      isDescFirst,
                    });
                }}
                //
                orderColumn={orderColumn}
                isSelected={isSortable ? true : selectedColumnIndex === index}
                index={index}>
                {label}
              </SortableTableHead>
            );
          })}
        {headerDataList.length === 0 &&
          headerTitles.map((headerTitle, index) => {
            return (
              <SortableTableHead
                isSortable={isSortable}
                key={headerTitle}
                index={index}
                isSelected={selectedColumnIndex === index}>
                {headerTitle}
              </SortableTableHead>
            );
          })}
      </StyledTableRow>
    </TableHeader>
  );

  // inner function
  /**
   * @typedef OnClickHeaderParams
   * @prop {number} index 정렬 기준으로 선택된 Column 의 순서(index)
   * @prop {string} orderColumn 정렬 기준이 되는 Field 명, API 요청 Parameter 에 동일한 값 사용
   * @prop {function} setOrderByType 정렬 기준과 정렬 방향 상태 변경 Handler
   * @prop {number} defaultSortType 정렬 방향, Const.ORDER_TYPE 기준 ASC: 1, DESC: 2, 정렬 기준 없음: 0
   * @prop {boolean} [isDescFirst] 정렬 방향 변경 순서 변경 여부, True 면 NONE -> DESC -> ASC -> NONE 으로 변경됨, 그렇지 않으면, NONE -> ASC -> DESC -> NONE 로 변경
   */
  /**
   *
   * @param {OnClickHeaderParams} param0
   */
  function _onClickHeader({
    index: clickedColumnIndex,
    orderColumn: clickedOrderColumn,
    setOrderByType,
    defaultSortType: orderType,
    isDescFirst,
  }) {
    // 검사 시작,종료일은 정렬순서를 뒤집어준다
    const reverseColumn = [sortTarget.startDatetime, sortTarget.endDatetime];
    const isReverse = isDescFirst ?? reverseColumn.includes(clickedOrderColumn);
    let newOrder = ORDER_TYPE.getNextOrderType(orderType, isReverse);

    setSelectedColumnIndex(clickedColumnIndex);
    setHeaderDataList((prev) => {
      return prev.map((v) => {
        if (
          v.sortOption.isSortable &&
          v.sortOption?.orderColumn === clickedOrderColumn
        ) {
          v.sortOption.defaultSortType = newOrder;
        } else {
          v.sortOption.defaultSortType = ORDER_TYPE.NONE;
        }
        return v;
      });
    });
    // 정렬 order by 설정(affect call fetch list api)
    setOrderByType((prev) => {
      const init = {};
      for (let property in prev) {
        init[property] = ORDER_TYPE.NONE;
      }

      return { ...init, [clickedOrderColumn]: newOrder };
    });
  }
}

export default SortableTableHeader;
