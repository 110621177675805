import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DATA_TEST_ID } from 'constant/DataTestId';

import { DialogWrapper } from 'component/ui/dialog/Dialog';
import { CloseIcon, SearchIcon } from 'component/ui/icons';

import { TitleText } from 'component/page/MainPage';
import { getAddressForParcelRequested } from 'redux/duck/ecgTestList/ecgTestListDuck';

import SearchInput from './SearchInput';
import AddressItem from './AddressItem';

interface ParcelAddressDialogProps {
  open: boolean;
  params: {
    onChangeInput: (value: string, key: string) => void;
  };
  onClose: () => void;
}

function ParcelAddressDialog({
  open,
  params: { onChangeInput },
  onClose,
}: ParcelAddressDialogProps) {
  const dispatch = useDispatch();
  const inputRef = useRef<any>(null);

  const [addressInput, setAddressInput] = useState<string>('');

  const handlePostParcelRequested = (body) => {
    dispatch(getAddressForParcelRequested(body));
  };

  useEffect(() => {
    inputRef.current?.getInputRef().focus();
  }, [inputRef]);

  const searchInputEndIconHandle = {
    endIcon:
      addressInput.length > 0 ? <CloseIconWrapper /> : <SearchIconWrapper />,
    onClickEndIcon: () => {
      if (addressInput.length > 0) {
        setAddressInput('');
      }
      inputRef.current?.getInputRef().focus();
    },
  };

  const onTextInputSubmit = ({ page, addressInput }) => {
    if (typeof addressInput !== 'string') return;

    const body = {
      currentPage: page,
      keyword: addressInput,
    };
    handlePostParcelRequested(body);
  };

  return (
    <DialogWrapper
      zIndex={12}
      borderColor="#FF0000"
      borderRadius={10}
      padding={20}
      open={open}
      width={400}
      innerStyle={{
        padding: '0',
        height: '570px',
      }}>
      <AddressDialogWrapper>
        <HeaderWrapper>
          <HeaderTitleWrapper>
            <TitleText>주소찾기</TitleText>
            <IconWrapper
              onClick={onClose}
              data-testid={DATA_TEST_ID.PARCEL_DIALOG.CLOSE}>
              <StyledCloseIcon />
            </IconWrapper>
          </HeaderTitleWrapper>
        </HeaderWrapper>
        <SearchInput
          ref={inputRef}
          addressInput={addressInput}
          setAddressInput={setAddressInput}
          endIcon={searchInputEndIconHandle.endIcon}
          onClickEndIcon={searchInputEndIconHandle.onClickEndIcon}
          onTextInputSubmit={onTextInputSubmit}
        />
        <ItemDivider />
        <AddressItem
          addressInput={addressInput}
          onClose={onClose}
          onChangeInput={onChangeInput}
          onTextInputSubmit={onTextInputSubmit}
        />
      </AddressDialogWrapper>
    </DialogWrapper>
  );
}

const AddressDialogWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 0px 0px 8px 20px;
`;

export const ItemDivider = styled.div`
  height: 1px;
  margin: 6px 0px 10px -20px;
  width: 400px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.COOL_GRAY_40}`};
`;

const SearchIconWrapper = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`;

const CloseIconWrapper = styled(CloseIcon)`
  width: 20px;
  height: 20px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  height: 54px;
`;

const IconWrapper = styled.div`
  cursor: ${({ isMemoCueEcgTest }: { isMemoCueEcgTest?: boolean }) =>
    isMemoCueEcgTest ? 'not-allowed' : 'pointer'};
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  pointer-events: ${({ isMemoCueEcgTest }: { isMemoCueEcgTest?: boolean }) =>
    isMemoCueEcgTest ? 'auto' : 'auto'};
  & + & {
    margin-left: 8px;
  }
  &:hover {
    background-color: ${({ theme, isMemoCueEcgTest }: any) =>
      isMemoCueEcgTest ? '' : theme.color.COOL_GRAY_30};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 20px;
  height: 20px;
`;

export default ParcelAddressDialog;
