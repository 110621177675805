import { useIntl } from 'react-intl';
import styled from 'styled-components';

import DateUtil from 'util/DateUtil';

import {
  StyledDropDownIcon,
  ValueText,
  ValueWrapper,
} from 'component/fragment/main/filterDropdown/EcgTestStatusFilterDropdown';

import MenuItem from 'component/ui/menu/MenuItem';
import { MenuBase } from 'component/ui/menu/Menu';
import DropdownBase, {
  CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE,
  CLASS_NAME_DROPDOWN_ANCHOR_HOVER,
} from 'component/ui/dropdown/DropdownBase';
import { CheckIcon, ArrowLeftIcon, ArrowRightIcon } from 'component/ui/icons';

const START_YEAR = 2021; // 시작 년도
const currentYear = DateUtil.getUserLocationTime().getFullYear(); // 현재 년도 구하기

function StartEndDatePickerCustomHeader(props) {
  const {
    // standard props
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    // custom functions and variables
    selectedMonth,
    selectedYear,
    children,
  } = props;

  const intl = useIntl();

  const currentLocale = intl.locale;
  const formattedYearMonth = new Date(
    `${selectedYear}-${selectedMonth + 1}`
  ).toLocaleString(currentLocale, {
    year: 'numeric',
    month: 'long',
  });

  // START_YEAR부터 currentYear까지의 년도 배열 생성
  const yearArray = Array.from(
    { length: currentYear - START_YEAR + 1 },
    (_, i) => START_YEAR + i
  );

  return (
    <Wrapper>
      {children}

      <MonthYearSelectorWrapper>
        <ArrowLeftIcon
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        />

        <DropdownBase
          anchorProps={{
            style: { padding: '5px', minHeight: '16px', border: 'none' },
          }}
          contentCloseReason="clickAway"
          dropdownDirection="left"
          value={
            <ValueWrapper>
              <ValueText>{formattedYearMonth}</ValueText>
              <StyledDropDownIcon />
            </ValueWrapper>
          }>
          <MenuBase>
            {yearArray.map((year, index) => {
              return (
                <MenuItem
                  style={{
                    width: 55,
                  }}
                  key={index}
                  onClick={({ target: { innerText } }) =>
                    changeYear(innerText)
                  }>
                  <YearLabel>
                    <StyledCheckIcon show={Number(year === selectedYear)} />
                    {year}
                  </YearLabel>
                </MenuItem>
              );
            })}
          </MenuBase>
        </DropdownBase>

        <ArrowRightIcon
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        />
      </MonthYearSelectorWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const MonthYearSelectorWrapper = styled.div`
  padding: 6px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg:hover {
    cursor: pointer;
  }

  & .${CLASS_NAME_DROPDOWN_ANCHOR_HOVER} {
    background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
  }
  & .${CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE} {
    background-color: ${({ theme }) => theme.color.WHITE};
    border-color: ${({ theme }) => theme.color.BLUE_70};
  }
`;

const YearLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
`;

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  left: -3px;
  ${(props) => (props.show ? '' : 'visibility: hidden;')};
  width: 14px;
  height: 14px;
  & path {
    fill: ${(props) => props.theme.color.COOL_GRAY_70};
  }
`;

export default StartEndDatePickerCustomHeader;
