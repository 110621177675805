import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import Const, { WRITING_TYPE } from 'constant/Const';
import { DATA_TEST_ID } from 'constant/DataTestId';

import { getLocale } from 'util/Utility';

import useMedicalFeeExcelDownload from 'component/hook/useMedicalFeeExcelDownload';

import TableListFilterHeaderFragmentContainer from 'redux/container/fragment/main/TableListFilterHeaderFragmentContainer';
import EcgTestListFragmentContainer from 'redux/container/fragment/main/EcgTestListFragmentContainer';
import TableSidePanelFragmentContainer from 'redux/container/fragment/main/TableSidePanelFragmentContainer';

import Button from 'component/ui/button/Button';
import Tooltip from 'component/ui/tooltip/Tooltip';
import { AddIcon, ExcelIcon } from 'component/ui/icons';

const CURRENT_LOCALE = getLocale();
const IS_KO = CURRENT_LOCALE === Const.LANGUAGE.KO;

export const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`;

export const InnerHeaderContainer = styled.div`
  position: sticky;
  // Layout Header: 50px | 114px
  top: ${(props) => props.theme.size.headerHeight}px;
  left: 0;
  right: 0;
  padding: 6px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-bottom: 1px solid rgba(230, 234, 236, 1);
  z-index: 2;
`;

const TableListWrapper = styled.div`
  position: relative;
  overflow: clip;
`;

export const TitleText = styled.div`
  flex: 5;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.color.COOL_GRAY_90};
`;

function MainPage(props) {
  const {
    // Redux state
    authUserAccessLevelState,
    // Redux dispatch
    handleShowDialog,
    handleFetchEcgTestsRequested,
  } = props;

  const intl = useIntl();
  const {
    downloadButtonText,
    downloadButtonTooltip,
    handelEcgTestListExcelDownload,
    downloadPending,
  } = useMedicalFeeExcelDownload();

  return (
    <Wrapper>
      <InnerHeaderContainer>
        <TitleText>{intl.formatMessage(INTL_MAP.INNER_TEXT)}</TitleText>

        {IS_KO && (
          <Tooltip
            title={downloadButtonTooltip}
            placement={'bottom'}
            option={{ maxWidth: 157 }}>
            <Button
              text={downloadButtonText}
              pending={downloadPending}
              startIcon={<ExcelIcon style={{ width: 16, height: 16 }} />}
              outline
              style={{
                width: 'initial',
                padding: '4px 8px',
                marginRight: 6,
              }}
              onClick={() => {
                handelEcgTestListExcelDownload();
              }}
            />
          </Tooltip>
        )}
        <Button
          dataTestId={DATA_TEST_ID.MAIN_PAGE.BUTTON.ADD_NEW_TEST}
          title={intl.formatMessage(INTL_MAP.ADD_NEW_TEST)}
          disabled={
            authUserAccessLevelState === Const.ACCESS_LEVEL.READ_ONLY.value
          }
          startIcon={<AddIcon style={{ width: 16, height: 16 }} />}
          style={{
            width: 'initial',
            padding: '4px 8px',
          }}
          onClick={() => {
            handleShowDialog({
              dialogKey: 'AddNewTestDialog',
              params: {
                // todo: jyoon - const로 뺄 필요 있음
                writingType: WRITING_TYPE.NEW,
                title: intl.formatMessage(INTL_MAP.ADD_NEW_TEST),
              },
              callback: () => {
                handleFetchEcgTestsRequested({
                  page: 1,
                });
              },
            });
          }}
        />
      </InnerHeaderContainer>

      <TableListWrapper>
        <TableListFilterHeaderFragmentContainer />
        <EcgTestListFragmentContainer />
        <TableSidePanelFragmentContainer />
      </TableListWrapper>
    </Wrapper>
  );
}

export default MainPage;

/**
 * - INNER_TEXT: 검사 목록
 * - ADD_NEW_TEST: 신규검사 추가
 */
const INTL_MAP = defineMessages({
  INNER_TEXT: {
    id: '02-MainPage-TitleText-innerText',
    description: '검사 목록',
    defaultMessage: '검사 목록',
  },
  ADD_NEW_TEST: {
    id: '02-MainPage-Button-title-01',
    description: '신규검사 추가',
    defaultMessage: '신규검사 추가',
  },
});
