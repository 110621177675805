import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Fragment } from 'react';

import { TEST_STATUS_ARRAY as _TEST_STATUS_ARRAY } from 'constant/Const';
import { DATA_TEST_ID } from 'constant/DataTestId';

import useFilterDropdown from 'component/hook/useFilterDropdown';

import DropdownBase, {
  CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE,
  CLASS_NAME_DROPDOWN_ANCHOR_HOVER,
} from 'component/ui/dropdown/DropdownBase';
import { MenuBase } from 'component/ui/menu/Menu';
import MenuItem from 'component/ui/menu/MenuItem';
import CheckBox from 'component/ui/checkbox/CheckBox';
import { DropDownIcon } from 'component/ui/icons';

const TEST_STATUS_ARRAY = _TEST_STATUS_ARRAY;

function EcgTestStatusFilterDropdown() {
  const {
    intl,
    tableFilterOptions: { ecgTestStatus },
    handleFetchEcgTestsRequested,
  } = useFilterDropdown();

  return (
    <DropdownBaseWrapper
      data-testid={
        DATA_TEST_ID.TABLE_LIST_FILTER_HEADER.ECG_TEST_STATUS_FILTER
      }>
      <DropdownBase
        anchorProps={{
          style: { padding: '8px', minHeight: '16px', height: '32px' },
        }}
        contentCloseReason="clickAway"
        dropdownDirection="left"
        label={
          <LabelWrapper>
            <LabelText>
              {intl.formatMessage(INTL_MAP.ECG_TEST_STATUS)}
            </LabelText>
          </LabelWrapper>
        }
        value={
          <ValueWrapper>
            <ValueText>
              <GenerateSelectedItemsText
                selectedItemList={_findLabelsByEcgStatus(ecgTestStatus)}
              />
            </ValueText>
            <StyledDropDownIcon />
          </ValueWrapper>
        }>
        <MenuBase style={{ width: 140 }}>
          {TEST_STATUS_ARRAY.map(({ label, key }) => {
            return (
              <MenuItem
                key={key}
                title={label}
                onClick={() => {
                  if (ecgTestStatus.includes(key)) {
                    handleFetchEcgTestsRequested({
                      ecgTestStatus: ecgTestStatus.filter(
                        (ecgTestStatusKeys) => ecgTestStatusKeys !== key
                      ),
                      page: 1,
                    });
                  } else {
                    handleFetchEcgTestsRequested({
                      ecgTestStatus: _sortEcgTestStatusByKey(
                        [...ecgTestStatus, key],
                        TEST_STATUS_ARRAY
                      ),
                      page: 1,
                    });
                  }
                }}>
                <CheckBox
                  checked={ecgTestStatus.includes(key)}
                  label={label}
                  labelStyle={{ paddingLeft: 7 }}
                />
              </MenuItem>
            );
          })}
          <RenderClearSelection
            show={ecgTestStatus.length > 1}
            onClick={_clearSelectionHandler}
          />
        </MenuBase>
      </DropdownBase>
    </DropdownBaseWrapper>
  );

  // inner Functions
  function _findLabelsByEcgStatus(ecgTestStatus) {
    const statusKeyMap = TEST_STATUS_ARRAY.reduce((acc, item) => {
      acc[item.key] = item.label;
      return acc;
    }, {});

    const labels = ecgTestStatus
      .map((key) => statusKeyMap[key])
      .filter((label) => label !== undefined);

    return labels;
  }
  function _sortEcgTestStatusByKey(ecgTestStatus, items) {
    const keyOrder = items.map((item) => item.key);

    return ecgTestStatus.sort(
      (a, b) => keyOrder.indexOf(a) - keyOrder.indexOf(b)
    );
  }
  function _clearSelectionHandler() {
    handleFetchEcgTestsRequested({ ecgTestStatus: [], page: 1 });
  }
}

export default EcgTestStatusFilterDropdown;

// export Component
export const RenderClearSelection = ({ show, onClick }) => {
  const intl = useIntl();

  if (!show) {
    return null;
  }

  return (
    <ClearSelectionWrapper>
      <Divider />
      <ClearSelectionMenuItem onClick={onClick}>
        {intl.formatMessage(INTL_MAP.DELETE_SELECTED_ITEMS)}
      </ClearSelectionMenuItem>
    </ClearSelectionWrapper>
  );
};

export const GenerateSelectedItemsText = ({ selectedItemList }) => {
  const intl = useIntl();

  const maxItemsToShow = 4;

  if (selectedItemList.length > maxItemsToShow) {
    const visibleItems = selectedItemList
      .slice(0, maxItemsToShow)
      .map((el, index) => (
        <Fragment key={index}>
          {el}
          {index < maxItemsToShow - 1 ? ', ' : ''}
        </Fragment>
      ));
    const remainingItemsCount = selectedItemList.length - maxItemsToShow;

    return intl.formatMessage(INTL_MAP.REMAINING_ITEMS_TEXT, {
      visibleItems,
      remainingItemsCount,
    });
  }

  return (
    <>
      {selectedItemList.map((el, index) => (
        <Fragment key={index}>
          {el}
          {index < selectedItemList.length - 1 ? ', ' : ''}
        </Fragment>
      ))}
    </>
  );
};

export const DropdownBaseWrapper = styled.div`
  & .${CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE} {
    background-color: ${({ theme }) => theme.color.WHITE};
    border-color: ${({ theme }) => theme.color.COOL_GRAY_50};
  }
  & .${CLASS_NAME_DROPDOWN_ANCHOR_HOVER} {
    background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
  }
`;

export const LabelWrapper = styled.div`
  gap: 2px;
  display: flex;
  align-content: stretch;
  align-items: center;
  line-height: 100%;
`;

export const ValueWrapper = styled.div`
  gap: 2px;
  margin-left: 2px;
  display: flex;
  align-content: stretch;
  align-items: center;
  line-height: 100%;
`;

export const TextBase = styled.div`
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
`;

export const LabelText = styled(TextBase)`
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

export const ValueText = styled(TextBase)`
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

export const StyledDropDownIcon = styled(DropDownIcon)`
  width: 16px;
  height: 16px;
  & path {
    fill: ${({ theme }) => theme.color.COOL_GRAY_80};
  }
`;

export const ClearSelectionMenuItem = styled(MenuItem)`
  padding: 5px 12px;
  height: 16px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
`;

export const ClearSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

/**
 * - ECG_TEST_STATUS: 상태
 * - DELETE_SELECTED_ITEMS: 선택한 항목 지우기
 * - REMAINING_ITEMS_TEXT: {visibleItems} 외 {remainingItemsCount}개
 */
const INTL_MAP = defineMessages({
  ECG_TEST_STATUS: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-ecgTestStatus',
    description: '상태',
    defaultMessage: '상태',
  },
  DELETE_SELECTED_ITEMS: {
    id: '03-EcgTestStatusFilterDropdown-RenderClearSelection-ClearSelectionMenuItem-label',
    description: '선택한 항목 지우기',
    defaultMessage: '선택한 항목 지우기',
  },
  REMAINING_ITEMS_TEXT: {
    id: '03-EcgTestStatusFilterDropdown-DropdownBase-remainingItemsText',
    description: '{visibleItems} 외 {remainingItemsCount}개',
    defaultMessage: '{visibleItems} 외 {remainingItemsCount}개',
  },
});
