import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => {
    switch (props.status) {
      case 'good':
        return props.theme.color.PRIMARY_BLUE;
      case 'bad':
        // return props.theme.color.RED;
        // TODO: revamp로 인해 Label 컴포넌트가 사용되지 않아 색상과 font-weight 변경
        return props.theme.color.RED_70;
      case 'disabled':
        return props.theme.color.COOL_GRAY_50;
      default:
        // normal
        return props.theme.color.BLACK;
    }
  }};
`;

/**
 *
 * @param { {text:string, status:['normal', 'good', 'bad']} } props
 * @returns
 */
function Label({ text, status = 'normal', ...props }) {
  return (
    <Wrapper status={status} {...props}>
      {text}
    </Wrapper>
  );
}

export default Label;
