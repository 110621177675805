import React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import styled from 'styled-components';

const StyledDrawer = styled(
  ({ isOpen, onClose, children, anchor = 'right', ...props }) => (
    <MuiDrawer
      anchor={anchor}
      variant="persistent"
      open={isOpen}
      onClose={onClose}
      {...props}>
      {children}
    </MuiDrawer>
  )
)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  & .MuiPaper-root {
    position: absolute;
    width: 400px;
    box-sizing: content-box;
    border-left: 1px solid ${({ theme }) => theme.color.COOL_GRAY_50};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14);
  }
`;

function SidePanelDrawer(props) {
  return <StyledDrawer {...props} />;
}

export default SidePanelDrawer;
