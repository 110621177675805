import React, { useEffect, useState, useImperativeHandle } from 'react';
import { useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';

import useIsMounted from 'component/hook/useIsMounted';

import Dropdown from 'component/ui/dropdown/Dropdown';
import {
  FirstPageIcon,
  PrevPageIcon,
  NextPageIcon,
  LastPageIcon,
} from 'component/ui/icon/PaginationIcon';

const Wrapper = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  border-top: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  border-bottom: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  background-color: ${(props) => props.theme.color.COOL_GRAY_20};
`;

const DropdownText = styled.div`
  margin-left: 8px;
  margin-right: 64px;
  font-size: 12px;
`;

const ItemCountText = styled.div`
  margin-right: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const IconWrapper = styled.div`
  display: flex;
  & > * {
    &.enabled {
      cursor: pointer;
    }
    :hover {
      background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
      border-radius: 3px;
    }
    :not(:last-child) {
      margin-right: 16px;
    }
    font-size: 14px;
    color: ${({ theme }) => theme.color.DARK};
  }
`;

const ITEM_COUNT_PER_PAGES = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 20,
    value: 20,
  },
  {
    label: 30,
    value: 30,
  },
  {
    label: 100,
    value: 100,
  },
];

/**
 * @typedef TablePaginationRevampProps
 * @prop {Object} [style] CSS styles.
 * @prop {number} [currentPage] 현재 페이지
 * @prop {number} [currentPageItemCount] 현재 페이지의 항목 수
 * @prop {number} [totalItemCount] 전체 항목 수
 * @prop {boolean} [isShowPerPage] 페이지 사이즈 제어 UI 사용 여부
 * @prop {(x: typeof ITEM_COUNT_PER_PAGES[0]) => void} [onChangeItemCount] 페이지 사이즈 상태 변경 Handler
 * @prop {number} [pageSize] 초기 페이지 사이즈
 * @prop {() => void} [onClickBackwardEnd] 첫 번째 페이지 이동 Handler
 * @prop {() => void} [onClickBackward] 이전 페이지 이동 Handler
 * @prop {() => void} [onClickForward] 다음 페이지 이동 Handler
 * @prop {() => void} [onClickForwardEnd] 마지막 번째 페이지 이동 Handler
 * @prop {({page: number}) => void} [loadPageData] 페이지 데이터 요청 Handler, 있다면 페이지 변경 시 우선 사용
 * @prop {*} [refreshButton] 조회 목록 새로고침 버튼
 */
/**
 *
 * @param {TablePaginationRevampProps} props
 * @param {*} ref
 * @returns
 */
function TablePagination(props, ref) {
  const {
    currentPage,
    currentPageItemCount,
    totalItemCount,
    //
    isShowPerPage = true,
    onChangeItemCount,
    pageSize,
    //
    onClickBackwardEnd,
    onClickBackward,
    onClickForward,
    onClickForwardEnd,
    loadPageData,
    //
    refreshButton,
  } = props;

  const intl = useIntl();
  const theme = useTheme();
  /** Page Size */
  const [selectedItem, setSelectedItem] = useState(() => {
    if (!isShowPerPage && pageSize) {
      return {
        label: pageSize,
        value: pageSize,
      };
    }

    return ITEM_COUNT_PER_PAGES[isShowPerPage ? 0 : 3];
  });
  const isMountedComponent = useIsMounted();

  useEffect(() => {
    if (!isMountedComponent) return;

    if (onChangeItemCount && typeof onChangeItemCount === 'function') {
      onChangeItemCount(selectedItem.value);
    }
  }, [selectedItem]);

  useImperativeHandle(ref, () => ({
    getItemCountPerPage: () => {
      return selectedItem.value;
    },
  }));

  const startItemIndex = (currentPage - 1) * selectedItem.value + 1;
  const endItemIndex = startItemIndex - 1 + currentPageItemCount;
  const isPrevPageAvailable = currentPage > 1;
  const isNextPageAvailable = endItemIndex < totalItemCount;
  const lastPage = Math.round(totalItemCount / selectedItem.value);

  return (
    <Wrapper style={props.style}>
      {isShowPerPage && (
        <>
          <Dropdown
            items={ITEM_COUNT_PER_PAGES}
            selectedItem={selectedItem}
            dropToTop={true}
            onChange={(item) => {
              setSelectedItem(item);
            }}
          />
          <DropdownText>
            {intl.formatMessage({
              id: '02-MainPage-DropdownText-innerText',
              description:
                '검사목록 게시글 페이지 사이즈 (예. 10개씩 보기, 30개씩 보기, 10개씩 보기)',
              defaultMessage: '개씩 보기',
            })}
          </DropdownText>
        </>
      )}

      <ItemCountText>
        {endItemIndex === 0 &&
          intl.formatMessage({
            id: '02-MainPage-Pagination-text-01',
            description: '0개 중 0',
            defaultMessage: '0개 중 0',
          })}
        {endItemIndex !== 0 &&
          intl.formatMessage(
            {
              id: '02-MainPage-Pagination-text-02',
              description: '517개 중 1-100',
              defaultMessage: '{total}개 중 {start}-{end}',
            },
            {
              total: totalItemCount,
              start: startItemIndex,
              end: endItemIndex,
            }
          )}
      </ItemCountText>

      <IconWrapper>
        <FirstPageIcon
          className={isPrevPageAvailable ? 'enabled' : ''}
          theme={theme}
          enabled={isPrevPageAvailable}
          onClick={() => {
            if (!isPrevPageAvailable) return;

            if (loadPageData && typeof loadPageData === 'function') {
              loadPageData({ page: 1 });
              return;
            }
            if (typeof onClickBackwardEnd === 'function') {
              onClickBackwardEnd();
            }
          }}
        />
        <PrevPageIcon
          className={isPrevPageAvailable ? 'enabled' : ''}
          theme={theme}
          enabled={isPrevPageAvailable}
          onClick={() => {
            if (!isPrevPageAvailable) return;

            if (loadPageData && typeof loadPageData === 'function') {
              loadPageData({ page: currentPage - 1 });
              return;
            }
            if (typeof onClickBackward === 'function') {
              onClickBackward();
            }
          }}
        />
        <NextPageIcon
          className={isNextPageAvailable ? 'enabled' : ''}
          theme={theme}
          enabled={isNextPageAvailable}
          onClick={() => {
            if (!isNextPageAvailable) return;

            if (loadPageData && typeof loadPageData === 'function') {
              loadPageData({ page: currentPage + 1 });
              return;
            }
            if (typeof onClickForward === 'function') {
              onClickForward();
            }
          }}
        />
        <LastPageIcon
          className={isNextPageAvailable ? 'enabled' : ''}
          theme={theme}
          enabled={isNextPageAvailable}
          onClick={() => {
            if (!isNextPageAvailable) return;

            if (loadPageData && typeof loadPageData === 'function') {
              loadPageData({ page: lastPage });
              return;
            }
            if (typeof onClickForwardEnd === 'function') {
              onClickForwardEnd();
            }
          }}
        />
      </IconWrapper>
      {refreshButton}
    </Wrapper>
  );
}

export default React.forwardRef(TablePagination);
