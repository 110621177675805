export function handleLinkNavigation({
  event,
  url,
  history,
}: {
  event: MouseEvent;
  url: string;
  history: any;
}) {
  if (event.metaKey || event.ctrlKey) {
    window.open(url);
  } else if (event.shiftKey) {
    window.open(url, '_blank');
  } else {
    history.push(url);
  }
}
