import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';

import DateUtil from 'util/DateUtil';

import { StyledDialogRowItem, StyledTextInput } from './FormInput';
import Label from './Label';
import { PARCEL_STATUS } from './ParcelConst';

function DatePickerInput({ isEditable, pickUpDate, ecgTest, onChangeInput }) {
  const { formatDateOnly, getUserLocationTime } = DateUtil;
  if (!isEditable) {
    const isCanceledStatus =
      ecgTest.parcel.status === PARCEL_STATUS.CANCELED.value;
    if (isCanceledStatus) return null;
    return <Label label={'택배 수거일'} value={pickUpDate} />;
  }

  return (
    <StyledDialogRowItem label={'택배 수거일'} required>
      <DatePickerWrapper>
        <ReactDatePicker
          // readOnly={!isEditable}
          dateFormat="yyyy-MM-dd"
          dateFormatCalendar="yyyy년 L월"
          formatWeekDay={formatWeekDay}
          popperPlacement="bottom"
          customInput={<StyledTextInput value={pickUpDate} />}
          minDate={
            ecgTest.estimatedEndDatetime
              ? new Date(ecgTest.estimatedEndDatetime)
              : getUserLocationTime()
          }
          selected={new Date(pickUpDate)}
          onChange={(value) => {
            onChangeInput(formatDateOnly(value), 'pickUpDate');
          }}
          disabledKeyboardNavigation
        />
      </DatePickerWrapper>
    </StyledDialogRowItem>
  );
}

function formatWeekDay(dayOfWeekLabel) {
  return dayOfWeekLabel.toString().charAt(0);
}

export default DatePickerInput;

const DatePickerWrapper = styled.div`
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 16px;
    color: ${({ theme }) => theme.color.WHITE};
    background-color: ${({ theme }) => theme.color.BLUE_70};
  }
  .react-datepicker__day-name {
    font-weight: 500;
  }
  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.color.COOL_GRAY_80};
  }
  .react-datepicker__current-month {
    font-weight: 500;
    color: ${({ theme }) => theme.color.COOL_GRAY_90};
  }
`;
