import Const from 'constant/Const';

import DateUtil from 'util/DateUtil';

import useManageParcelFormData from 'component/hook/useManageParcelFormData';

import { DialogWrapper } from 'component/ui/dialog/Dialog';

import { PARCEL_STATUS } from 'component/dialog/ManageParcelDialog/ParcelConst';

import DialogButton from './DialogButton';
import Title from './Title';
import Precaution from './Precaution';
import Content from './Content';

const { isSameAsToday } = DateUtil;
const { NONE, RECEIPT_AWAITING } = PARCEL_STATUS;
const editableStatus = new Set(
  [NONE, RECEIPT_AWAITING].map((status) => status.value)
);

function ManageParcelDialog({
  open,
  params: { ecgTest, parcel, handleShowDialog },
  onClose,
  onCancel,
}) {
  const isCreatedToday = !parcel.createdAt || isSameAsToday(parcel.createdAt);
  const isEditable =
    editableStatus.has(parcel.status) &&
    isCreatedToday &&
    ecgTest.ecgTestStatus <= Const.ECG_TEST_STATUS.ON_GOING_TEST;

  const { formData, onChangeInput, isFormModified } = useManageParcelFormData(
    ecgTest,
    parcel
  );

  return (
    <DialogWrapper zIndex={11} open={open} width={360}>
      <Title isEditable={isEditable} status={parcel.status} />

      <Content
        ecgTest={ecgTest}
        parcel={parcel}
        formData={formData}
        isEditable={isEditable}
        onChangeInput={onChangeInput}
        handleShowDialog={handleShowDialog}
      />

      <Precaution isEditable={isEditable} isCreatedToday={isCreatedToday} />

      <DialogButton
        ecgTest={ecgTest}
        parcel={parcel}
        formData={formData}
        isEditable={isEditable}
        isFormModified={isFormModified}
        onClose={onClose}
        onCancel={onCancel}
      />
    </DialogWrapper>
  );
}

export default ManageParcelDialog;
