import { DATA_TEST_ID } from 'constant/DataTestId';

export function FirstPageIcon({ className, theme, enabled = false, onClick }) {
  return (
    <svg
      data-testid={DATA_TEST_ID.TABLE_PAGINATION.FIRST_PAGE}
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}>
      <path
        d="M19.688 20.125 13.563 14l6.124-6.125M8.75 20.125V7.875"
        stroke={enabled ? theme.color.COOL_GRAY_80 : theme.color.COOL_GRAY_50}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PrevPageIcon({ className, theme, enabled = false, onClick }) {
  return (
    <svg
      data-testid={DATA_TEST_ID.TABLE_PAGINATION.PREV_PAGE}
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}>
      <path
        d="M16.625 20.125 10.5 14l6.125-6.125"
        stroke={enabled ? theme.color.COOL_GRAY_80 : theme.color.COOL_GRAY_50}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function NextPageIcon({ className, theme, enabled = false, onClick }) {
  return (
    <svg
      data-testid={DATA_TEST_ID.TABLE_PAGINATION.NEXT_PAGE}
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}>
      <path
        d="M11.375 7.875 17.5 14l-6.125 6.125"
        stroke={enabled ? theme.color.COOL_GRAY_80 : theme.color.COOL_GRAY_50}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LastPageIcon({ className, theme, enabled = false, onClick }) {
  return (
    <svg
      data-testid={DATA_TEST_ID.TABLE_PAGINATION.LAST_PAGE}
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}>
      <path
        d="M8.75 7.875 14.875 14 8.75 20.125m10.938-12.25v12.25"
        stroke={enabled ? theme.color.COOL_GRAY_80 : theme.color.COOL_GRAY_50}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
