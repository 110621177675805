import styled from 'styled-components';

import useManageParcelDialogOnClickButtonHandler from 'component/hook/useManageParcelDialogOnClickButtonHandler';

import { SearchIcon } from 'component/ui/icons';

import { StyledDialogRowItem, StyledTextInput } from './FormInput';
import Label from './Label';

function AddressFormInput({
  ecgTest,
  parcel,
  formData,
  isEditable,
  onChangeInput,
  handleShowDialog,
}) {
  const { address1, address2 } = formData;

  const { handleResetAddressForParcel } =
    useManageParcelDialogOnClickButtonHandler({
      ecgTest,
      parcel,
      formData,
    });

  if (!isEditable) {
    return (
      <Label label="주소" height={'auto'} value={`${address1},${address2}`} />
    );
  }

  const onClickInsertParcelAdd = () => {
    handleResetAddressForParcel();
    handleShowDialog('ParcelAddDialog', {
      ecgTest,
      parcel,
      onChangeInput,
    });
  };
  const formatAddress1Value = (value) => {
    return value.length > 20 ? value.substring(0, 20) + '...' : value;
  };

  return (
    <StyledDialogRowItem height={70} labelAlign={'start'} label="주소" required>
      <AddressInputWrapper>
        <InputWrapper onClick={onClickInsertParcelAdd}>
          <StyledTextInput
            readOnly
            placeholder="주소"
            value={formatAddress1Value(address1)}
          />
          <EndIconWrapper>
            <SearchIconWrapper />
          </EndIconWrapper>
        </InputWrapper>
        <StyledTextInput
          placeholder="상세 주소"
          value={address2}
          onChange={(value) => onChangeInput(value, 'address2')}
        />
      </AddressInputWrapper>
    </StyledDialogRowItem>
  );
}

export default AddressFormInput;

const AddressInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const EndIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.color.COOL_GRAY_70};
  }
`;

const SearchIconWrapper = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`;
