import React from 'react';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import Button from './Button';
import DropdownBase, {
  CLASS_NAME_DROPDOWN_ANCHOR,
  CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE,
} from '../dropdown/DropdownBase';
import { DropDownIcon } from '../icons';
import styled from 'styled-components';

type SubComponentProps = {
  readonly style?: React.CSSProperties;
  readonly [x: string]: any;
};
type SplitButtonProps = {
  readonly children: React.ReactNode;
  readonly disabled?: boolean;
  readonly text: string;
  readonly icon: React.ReactNode;
  readonly type?: 'default' | 'icon-only';
  readonly onClick: () => void;
  readonly groupProps?: SubComponentProps;
  readonly buttonProps?: SubComponentProps;
  readonly anchorProps?: SubComponentProps;
};
function SplitButton(
  props: SplitButtonProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const {
    //
    children,
    disabled = false,
    text,
    icon,
    type = 'default',
    onClick,
    groupProps = {},
    buttonProps = {},
    anchorProps = {},
  } = props;

  const buttonHeight = type === 'default' ? 28 : 24;
  const anchorWidth = type === 'default' ? 26 : 18;
  const anchorIconSize = type === 'default' ? 20 : 16;
  const anchorPadding = type === 'default' ? '3px 2px' : '3px 0';

  return (
    <Wrapper disabled={disabled}>
      <ButtonGroup
        ref={ref}
        style={{
          height: buttonHeight,
          //
          ...(groupProps.style ?? {}),
        }}>
        <Button
          disabled={disabled}
          outline
          iconColor="COOL_GRAY_80"
          color="secondary"
          style={{
            width: 'auto',
            height: buttonHeight,
            padding: 4,
            flex: 1,
            //
            ...(buttonProps.style ?? {}),
          }}
          startIcon={icon}
          text={text}
          onClick={onClick}
        />
        <DropdownBase
          disabled={disabled}
          anchorProps={{
            style: {
              padding: anchorPadding,
              height: buttonHeight,
              minWidth: anchorWidth,
              maxWidth: anchorWidth,
              //
              ...(anchorProps.style ?? {}),
            },
          }}
          dropdownDirection="right"
          value={
            <DropDownIcon
              style={{ height: anchorIconSize, width: anchorIconSize }}
            />
          }
          contentCloseReason="default">
          {children}
        </DropdownBase>
      </ButtonGroup>
    </Wrapper>
  );
}

type WrapperProps = {
  readonly disabled: boolean;
};
const Wrapper = styled.div<WrapperProps>`
  & .${CLASS_NAME_DROPDOWN_ANCHOR} svg path {
    fill: ${(props) =>
      props.disabled
        ? props.theme.color.COOL_GRAY_50
        : props.theme.color.COOL_GRAY_80};
  }
  & .${CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE} svg path {
    fill: ${(props) => props.theme.color.COOL_GRAY_90};
  }
`;

export default React.forwardRef(SplitButton);
