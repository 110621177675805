import { downloadReportAssociatedFile } from 'util/FileUtil';

/**
 * report 관련 파일 다운로드 util hook
 * @returns
 */
function useReportFileDownload() {
  const downloadReportImageZipFile = async (
    zipFileUrl: string,
    handleReportDownloadStatusCheck: (uploadStatus: boolean) => void
  ) => {
    await downloadReportAssociatedFile(
      zipFileUrl,
      null,
      handleReportDownloadStatusCheck
    );
  };

  const downloadReportImageFiles = (
    imgFileUrls: string[],
    handleReportDownloadStatusCheck: (uploadStatus: boolean) => void
  ) => {
    if (!Array.isArray(imgFileUrls)) {
      console.error('imgFileUrls is not array');
    }

    const wrapper = document.createElement('div');
    wrapper.style.display = 'none';

    //고객 요구사항으로 인해 zip 파일을 다운로드 받지 않고, 각각의 jpg 파일을 다운로드 받도록 함
    imgFileUrls.forEach((imgFileUrl) => {
      const iFrame = document.createElement('iframe');
      iFrame.sandbox.add('allow-downloads');
      iFrame.src = imgFileUrl;
      wrapper.appendChild(iFrame);
    });

    document.body.appendChild(wrapper);
    handleReportDownloadStatusCheck(true);
    setTimeout(() => {
      document.body.removeChild(wrapper);
    }, 5000);
  };

  /**
   *  report jpg 파일들을 다운로드
   *
   * @param zipFileUrl 다운로드 받을 report jpg zip file url array
   * @returns
   */

  const downloadReportFindingsZipFile = async (
    zipFileUrl: string,
    handleReportDownloadStatusCheck: (uploadStatus: boolean) => void
  ) => {
    if (!zipFileUrl) {
      console.error('jpgFileUrls is empty');
      return;
    }

    //고대 EMR 연동을 위한 한정 코드
    const url = new URL(zipFileUrl);
    const splitted = url.pathname.split('/');
    const suggestedFileName = splitted.at(-1) ?? '';

    await downloadReportAssociatedFile(
      zipFileUrl,
      suggestedFileName,
      handleReportDownloadStatusCheck
    );
  };

  const downloadReportExtraTxtFile = async (
    txtFileUrl: string,
    handleReportDownloadStatusCheck: (uploadStatus: boolean) => void
  ) => {
    if (!txtFileUrl) {
      console.error('txtFileUrls is empty');
      return;
    }

    //고대 EMR 연동을 위한 한정 코드
    const url = new URL(txtFileUrl);
    const splitted = url.pathname.split('/');
    const suggestedFileName = splitted.at(-1) ?? '';

    await downloadReportAssociatedFile(
      txtFileUrl,
      suggestedFileName,
      handleReportDownloadStatusCheck
    );
  };

  return {
    downloadReportImageZipFile,
    downloadReportImageFiles,
    downloadReportFindingsZipFile,
    downloadReportExtraTxtFile,
  };
}

export default useReportFileDownload;
