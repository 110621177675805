import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import Const from 'constant/Const';

import DateUtil from 'util/DateUtil';

import {
  TableWrapper,
  Table,
  TableBody,
  TableRowEmptyLabel,
} from 'component/ui/table/Table';
import TablePagination from 'component/ui/table/TablePagination';
import SortableTableHeader from 'component/ui/table/SortableTableHeader';
import Button from 'component/ui/button/Button';
import BarProgress from 'component/ui/progress/BarProgress';
import { SyncIcon } from 'component/ui/icons';

import TableRowData from './TableRowData';

const {
  FETCH_TYPE: { FETCH_MODE },
} = Const;

function EcgTestListFragment({
  headerDataList,
  loadPageData,

  // Redux state
  ecgTestsFetchState,
  ecgTestSidePanelState,
  validReportPending,
  medicalStaffsFetchPending,
  isUseParcel,
  // Redux dispatch
  handleShowDialog,
  handleShowSidePanel,
  handleRevertStatusRequested,
}) {
  const intl = useIntl();

  return (
    <Wrapper>
      <Wrap className="Wrapper" isSidePanelOpen={ecgTestSidePanelState.isOpen}>
        <TableWrapper className="TableWrapper">
          <TableContent className="TableContent">
            <Table className="Table">
              <SortableTableHeader
                headerDataList={headerDataList}
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                }}
              />
              <TableBody>
                {ecgTestsFetchState?.data.map((ecgTest, index) => {
                  if (!ecgTest) return null;

                  const selectedTid = ecgTestSidePanelState.ecgTest?.tid;

                  return (
                    <TableRowData
                      key={ecgTest.tid}
                      index={index}
                      ecgTest={ecgTest}
                      selectedTid={selectedTid}
                      validReportPending={validReportPending}
                      medicalStaffsFetchPending={medicalStaffsFetchPending}
                      isUseParcel={isUseParcel}
                      ecgTestsFetchState={ecgTestsFetchState}
                      handleShowSidePanel={handleShowSidePanel}
                      handleShowDialog={handleShowDialog}
                      handleRevertStatusRequested={handleRevertStatusRequested}
                      loadPageData={loadPageData}
                    />
                  );
                })}
              </TableBody>
            </Table>
            <EmptyTableContentPlaceHolder
              numOfEmptyRow={ecgTestsFetchState.data.length}
              fetchType={ecgTestsFetchState.tableFilterOptions.fetchType}
              pending={ecgTestsFetchState.pending}
            />
          </TableContent>
          {!!ecgTestsFetchState.data?.length && ecgTestsFetchState.pending && (
            <BarProgress />
          )}
          <TablePagination
            style={{
              width: '100%',
              height: 40,
              padding: '0px 10px 0px 0px',
            }}
            currentPage={ecgTestsFetchState.tableFilterOptions.page}
            currentPageItemCount={ecgTestsFetchState.data.length}
            totalItemCount={
              ecgTestsFetchState.tableFilterOptions.totalCount || 0
            }
            onChangeItemCount={() => {
              loadPageData({
                page: ecgTestsFetchState.tableFilterOptions.firstPage,
              });
            }}
            onClickBackwardEnd={() => {
              loadPageData({
                page: ecgTestsFetchState.tableFilterOptions.firstPage,
              });
            }}
            onClickBackward={() => {
              loadPageData({
                page: ecgTestsFetchState.tableFilterOptions.page - 1,
              });
            }}
            onClickForward={() => {
              loadPageData({
                page: ecgTestsFetchState.tableFilterOptions.page + 1,
              });
            }}
            onClickForwardEnd={() => {
              loadPageData({
                page: ecgTestsFetchState.tableFilterOptions.lastPage,
              });
            }}
            isShowPerPage={false}
            refreshButton={
              <RefreshButton
                onClick={() => {
                  loadPageData();
                }}
                outline
                color={'secondary'}
                title={intl.formatMessage(INTL_MAP.REFRESH)}
                startIcon={<SyncIconWrapper />}
                iconColor={'COOL_GRAY_90'}
              />
            }
          />
        </TableWrapper>
      </Wrap>
    </Wrapper>
  );
}

export function EmptyTableContentPlaceHolder({
  numOfEmptyRow,
  fetchType,
  pending,
}) {
  const intl = useIntl();

  if (numOfEmptyRow > 0) return null;

  return (
    <TableRowEmptyLabel>
      {pending && (
        <BarProgressWrapper>
          <LoadingDataMessage>
            {intl.formatMessage(INTL_MAP.LOADING_DATA)}
          </LoadingDataMessage>
          <BarProgress
            style={{ width: '228px', marginTop: '16px' }}
            width={228}
          />
        </BarProgressWrapper>
      )}
      {!pending &&
        intl.formatMessage(
          fetchType === FETCH_MODE
            ? INTL_MAP.EMPTY_LABEL_01
            : INTL_MAP.EMPTY_LABEL_02
        )}
    </TableRowEmptyLabel>
  );
}

// util funcs
export function EstimatedEndAndReturnDateTime({
  returnDatetime,
  estimatedEndDatetime,
  endTimestamp,
  dateFormat = 'yyyy-MM-dd',
}) {
  const intl = useIntl();

  if (endTimestamp) {
    return DateUtil.formatDate(endTimestamp, dateFormat);
  }

  if (returnDatetime) {
    return DateUtil.formatDate(returnDatetime, dateFormat);
  }

  if (estimatedEndDatetime) {
    return intl.formatMessage(INTL_MAP.ESTIMATED_RETURN_DATETIME, {
      endDatetime: DateUtil.formatDate(estimatedEndDatetime, dateFormat),
    });
  }

  return '-';
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Wrap = styled.div`
  padding: 0 20px;
  background-color: ${({ theme }) => theme.color.WHITE};
  width: 100%;
  z-index: ${({ isSidePanelOpen }) => isSidePanelOpen && 11};
  @media screen and (min-width: 1920px) {
    width: 80%;
  }
`;

const FooterHeight = 40;
const TableContent = styled.div`
  width: 100%;
  overflow-y: auto;
  // Filter: 56 + Page Title: 45 + Something: 5 + Header: 50 | 114
  height: calc(
    100vh - 56px - 45px - 5px - ${({ theme }) => theme.size.headerHeight}px -
      ${FooterHeight}px
  );
`;

const RefreshButton = styled(Button)`
  width: 83px;
  height: 28px;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
  border: 1px solid ${({ theme }) => theme.color.COOL_GRAY_50};
  background-color: ${({ theme }) => theme.color.COOL_GRAY_20};
  line-height: 1;
  margin-left: 20px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
  }
`;

const SyncIconWrapper = styled(SyncIcon)`
  width: 16px;
  height: 16px;
`;

const BarProgressWrapper = styled.div`
  width: 228px;
  height: 56px;
`;

const LoadingDataMessage = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

const INTL_MAP = defineMessages({
  LOADING_DATA: {
    id: '03-EcgTestListFragment-TableRowEmptyLabel-LoadingDataMessage',
    description: '데이터를 불러오고 있습니다. / Loading data...',
    defaultMessage: '데이터를 불러오고 있습니다.',
  },
  EMPTY_LABEL_01: {
    id: '03-EcgTestListFragment-TableRowEmptyLabel-01',
    description: '데이터가 없습니다.',
    defaultMessage: '데이터가 없습니다.',
  },
  EMPTY_LABEL_02: {
    id: '03-EcgTestListFragment-TableRowEmptyLabel-02',
    description: '검색 결과가 없습니다.',
    defaultMessage: '검색 결과가 없습니다.',
  },
  REFRESH: {
    id: '03-EcgTestListFragment-RefreshButton-title',
    description: '새로고침',
    defaultMessage: '새로고침',
  },
  ESTIMATED_RETURN_DATETIME: {
    id: '03-EcgTestListFragment-Table-estimatedEndDatetime-01',
    description: '2023-03-15(반납예정)',
    defaultMessage: '{endDatetime}(반납예정)',
  },
});

export default EcgTestListFragment;
