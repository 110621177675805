import Const from './Const';

export const sortTarget = {
  createdAt: 'createdAt', // 검사 생성
  startDatetime: 'startDatetime', // 시작일
  estimatedEndDatetime: 'estimatedEndDatetime', // 종료예정일
  uploadDatetime: 'uploadDatetime', // 업로드 시점
  patchecgStartTimestamp: 'startTimestamp', // 시작일
  patchecgEndTimestamp: 'endTimestamp', // 종료일
  internalConfirmDatetime: 'internalConfirmDatetime', // 분석 완료일
  customerConfirmDatetime: 'customerConfirmDatetime', // 검토 완료일
  recordingTime: 'recordingTime', // 실 측정기간

  // ecg-test v2에서 사용되는 ordering list
  patientName: 'patientName',
  patientNumber: 'patientNumber',
  deviceNumber: 'deviceNumber',
  testStatus: 'testStatus',
  endDatetime: 'endDatetime',
  prescriptionDuration: 'prescriptionDuration',
};

export const tableSortInfoByStep = {
  onGoingTest: {
    default: { [sortTarget.createdAt]: Const.ORDER_TYPE.DESCENDING },
    sortTarget: {
      [sortTarget.startDatetime]: sortTarget.startDatetime,
      [sortTarget.estimatedEndDatetime]: sortTarget.estimatedEndDatetime,
    },
  },
  testReviewing: {
    default: {
      [sortTarget.customerConfirmDatetime]: Const.ORDER_TYPE.DESCENDING,
    },
    sortTarget: {
      [sortTarget.patchecgStartTimestamp]: sortTarget.patchecgStartTimestamp,
      [sortTarget.patchecgEndTimestamp]: sortTarget.patchecgEndTimestamp,
      [sortTarget.internalConfirmDatetime]: sortTarget.internalConfirmDatetime,
    },
  },
  testConfirmed: {
    default: { [sortTarget.uploadDatetime]: Const.ORDER_TYPE.DESCENDING },
    sortTarget: {
      [sortTarget.patchecgStartTimestamp]: sortTarget.patchecgStartTimestamp,
      [sortTarget.patchecgEndTimestamp]: sortTarget.patchecgEndTimestamp,
    },
  },
};

export const tableSortInfo = {
  // default: { [sortTarget.createdAt]: Const.ORDER_TYPE.DESCENDING },
  default: {
    [sortTarget.patientNumber]: Const.ORDER_TYPE.NONE,
    [sortTarget.patientName]: Const.ORDER_TYPE.NONE,
    [sortTarget.deviceNumber]: Const.ORDER_TYPE.NONE,
    [sortTarget.testStatus]: Const.ORDER_TYPE.NONE,
    [sortTarget.prescriptionDuration]: Const.ORDER_TYPE.NONE,
    [sortTarget.startDatetime]: Const.ORDER_TYPE.NONE,
    [sortTarget.endDatetime]: Const.ORDER_TYPE.NONE,
    [sortTarget.recordingTime]: Const.ORDER_TYPE.NONE,
    [sortTarget.internalConfirmDatetime]: Const.ORDER_TYPE.NONE,
  },
  sortTarget: {
    [sortTarget.estimatedEndDatetime]: sortTarget.estimatedEndDatetime,
    [sortTarget.customerConfirmDatetime]: sortTarget.customerConfirmDatetime,
    [sortTarget.internalConfirmDatetime]: sortTarget.internalConfirmDatetime,
    [sortTarget.patchecgStartTimestamp]: sortTarget.patchecgStartTimestamp,
    [sortTarget.patchecgEndTimestamp]: sortTarget.patchecgEndTimestamp,
    [sortTarget.recordingTime]: sortTarget.recordingTime,

    // ecg test V2의 ordering
    [sortTarget.patientName]: sortTarget.patientName,
    [sortTarget.patientNumber]: sortTarget.patientNumber,
    [sortTarget.deviceNumber]: sortTarget.deviceNumber,
    [sortTarget.testStatus]: sortTarget.testStatus,
    [sortTarget.prescriptionDuration]: sortTarget.prescriptionDuration,
    [sortTarget.startDatetime]: sortTarget.startDatetime,
    [sortTarget.endDatetime]: sortTarget.endDatetime,
    [sortTarget.prescriptionDuration]: sortTarget.prescriptionDuration,
  },
};

export const allDefaultSort = {
  [sortTarget.createdAt]: Const.ORDER_TYPE.DESCENDING,
};
