import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';
import Backdrop from '@mui/material/Backdrop';

import Const from 'constant/Const';
import { genderInfo, pacemakerInfo } from 'constant/EcgTestInfoConst';
import { DATA_TEST_ID } from 'constant/DataTestId';

import DateUtil from 'util/DateUtil';
import NumberUtil from 'util/NumberUtil';
import { debounce } from 'util/Utility';
import {
  haveDifferentWords,
  validationBirthDay,
  validationPhoneNumber,
  validationPrescribedBy,
} from 'util/validation/AddNewTestDialog';
import { getParsedTestDuration } from 'util/TestDataUtil';

import useDoctorsDropdown from 'component/hook/useDoctorsDropdown';
import useSnackbarStack from 'component/hook/useSnackbarStack';
import usePrevious from 'component/hook/usePrevious';

import { EstimatedEndAndReturnDateTime } from 'component/fragment/main/EcgTestListFragment';
import EcgTestDataItem from 'component/fragment/main/ecgTestDataItem/EcgTestDataItem';

import SidePanelDrawer from 'component/ui/drawer/SidePanelDrawer';
import Label from 'component/ui/label/Label';
import TextAreaInput from 'component/ui/input/TextAreaInput';
import CommonButton from 'component/ui/button/Button';
import DropdownBase, {
  CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE,
  CLASS_NAME_DROPDOWN_ANCHOR_HOVER,
} from 'component/ui/dropdown/DropdownBase';
import Tooltip from 'component/ui/tooltip/Tooltip';
import { MenuBase } from 'component/ui/menu/Menu';
import MenuItem from 'component/ui/menu/MenuItem';
import TextInput from 'component/ui/input/TextInput';
import {
  CLASS_NAME_OLD_DROPDOWN_ANCHOR,
  CLASS_NAME_OLD_DROPDOWN_ANCHOR_ACTIVE,
  CLASS_NAME_OLD_DROPDOWN_ANCHOR_HOVER,
} from 'component/ui/dropdown/Dropdown';
import {
  DropDownIcon,
  EditIcon,
  DeleteIcon,
  CloseIcon,
  InformationPatientIcon,
  InformationMeasurementIcon,
  CommentIcon,
  IconLinkOff,
  BlockedEditIcon,
  BlockedDeletedIcon,
} from 'component/ui/icons';

import { PARCEL_STATUS } from 'component/dialog/ManageParcelDialog/ParcelConst';

import { EcgTestFromCueText, EcgTestFromCueWrapper } from './TableRowData';

const Wrapper = styled.div``;

const DrawerBackdrop = styled(Backdrop)`
  z-index: 9;
`;

const SidePanelHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 18px;
  border-bottom: 1px solid ${({ theme }) => theme.color.MEDIUM_LIGHT};
  background: ${({ theme }) => theme.color.WHITE};
`;

const LabelText = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  & ${LabelText} {
    color: ${({ theme, disabled }) =>
      disabled ? theme.color.COOL_GRAY_40 : theme.color.COOL_GRAY_90};
  }
  & svg {
    display: ${({ disabled }) => disabled && 'none'};
  }
`;

const IconWrapper = styled.div`
  cursor: ${({ isMemoCueEcgTest }) =>
    isMemoCueEcgTest ? 'not-allowed' : 'pointer'};
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  pointer-events: ${({ isMemoCueEcgTest }) =>
    isMemoCueEcgTest ? 'auto' : 'auto'};
  & + & {
    margin-left: 8px;
  }
  &:hover {
    background-color: ${({ theme, isMemoCueEcgTest }) =>
      isMemoCueEcgTest ? '' : theme.color.COOL_GRAY_30};
  }
`;

const MenuGroup = styled.div`
  & + &:before {
    content: '';
    display: block;
    height: 1px;
    background: ${({ theme }) => theme.color.COOL_GRAY_40};
    margin: 6px 0;
  }

  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const SidePanelContent = styled.div`
  padding: 13px 0px 13px 18px;
  overflow: scroll;
  flex: 1;
`;

const SidePanelComment = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.MEDIUM_LIGHT};
  background: ${({ theme }) => theme.color.WHITE};
  height: 200px;
  padding: 0 18px;
  margin-bottom: 14px;
`;

const SidePanelFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 18px 14px 0;
  gap: 6px;
`;

const MenuTitle = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.COOL_GRAY_80};
  font-size: 11px;
  font-weight: 700;
  flex: 1;
`;
const MenuTitleHelperText = styled.div`
  max-width: 190px;
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  white-space: nowrap;
`;
const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ItemInput = styled.input`
  padding: 6px 8px 6px 12px;
  width: 236px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.WHITE};

  border-radius: 6px;
  border: 0px;
  outline: 1px solid ${({ theme }) => theme.color.COOL_GRAY_40};

  font-size: 12px;
  line-height: 100%;
  font-weight: 400;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};

  &::placeholder {
    color: ${({ theme }) => theme.color.COOL_GRAY_70};
  }
  &:hover {
    :not(:disabled) {
      outline: 1px solid ${({ theme }) => theme.color.COOL_GRAY_60};
    }
  }
  &:active,
  &:focus {
    :not(:disabled) {
      outline: 1px solid ${({ theme }) => theme.color.BLUE_70};
    }
  }
  &:disabled {
    color: ${({ theme }) => theme.color.COOL_GRAY_50};
  }
  &:invalid {
    :not(:disabled) {
      outline: 1px solid ${({ theme }) => theme.color.RED_70};
    }
  }
`;

const ItemText = styled.div`
  width: 236px;
  padding: 6px 8px 6px 12px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
  font-size: 12px;
  font-weight: 500;
`;
const LabelContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const RollbackDownloadWrapper = styled.div`
  display: flex;
  width: 236px;
  padding: 0px 8px 6px 12px;
  align-items: flex-start;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
  font-size: 12px;
  font-weight: 500;
  white-space: pre-wrap;
`;

// todo: jyoon - 검사 목록 side panel, 검사 상세 general에서 공통으로 사용하는 정보, component 분리 필요(240508)
export const ItemGap = styled.label`
  margin: 0px 4px 0px 0px;
`;
export const ItemHighLightText = styled.label`
  display: inline;
  box-sizing: border-box;
  //
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  //
  gap: 10px;
  height: 16px;
  padding: 2px 3px;
  border-radius: 2px;
  //
  color: ${({ theme }) => theme.color.COOL_GRAY_80};
  background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
`;

export const RollbackDownload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: center;
  text-decoration: underline;
  width: 69px;
  height: 20px;
  border-radius: 6px;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.color.COOL_GRAY_30};
    color: ${({ theme }) => theme.color.COOL_GRAY_90};
  }
`;

const SidePanelDropdownWrapper = styled.div`
  & .${CLASS_NAME_DROPDOWN_ANCHOR_HOVER} {
    background-color: ${({ theme }) => theme.color.WHITE};
    border-color: ${({ theme }) => theme.color.COOL_GRAY_60};
  }

  & .${CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE} {
    background-color: ${({ theme }) => theme.color.WHITE};
    border-color: ${({ theme }) => theme.color.BLUE_70};
  }

  & .${CLASS_NAME_OLD_DROPDOWN_ANCHOR} {
    height: 28px;
    width: 236px;
    box-sizing: border-box;

    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
  }

  & .${CLASS_NAME_OLD_DROPDOWN_ANCHOR_HOVER} {
    background-color: ${({ theme }) => theme.color.WHITE};
    border-color: ${({ theme }) => theme.color.COOL_GRAY_60};
  }

  & .${CLASS_NAME_OLD_DROPDOWN_ANCHOR_ACTIVE} {
    background-color: ${({ theme }) => theme.color.WHITE};
    border-color: ${({ theme }) => theme.color.BLUE_70};
  }
`;

const NumberInputWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  width: 236;

  input {
    padding: 6px 12px;
  }
`;

// TableSidePanel는 수정모드와 읽기모드가 있습니다
// 모드에 따라 MenuListItem의 자식 컴포넌트의 display 여부가 달라집니다
// 수정모드일때는 input 관련 컴포넌트가 표시되고, 읽기모드일때는 ItemText가 표시됩니다
// (= 수정모드일때는 ItemText가 숨겨지고, 읽기모드일때는 input 관련 컴포넌트가 숨겨집니다)
const MenuListItem = styled.li`
  height: 40px;
  display: flex;
  align-items: center;

  // 읽기모드일때 아래 3개 컴포넌트를 표시하지 않는다 (isEditMode === false)
  & ${ItemInput} {
    display: ${({ isEditMode }) => !isEditMode && 'none'};
  }
  & ${SidePanelDropdownWrapper} {
    display: ${({ isEditMode }) => !isEditMode && 'none'};
  }
  & ${NumberInputWrapper} {
    display: ${({ isEditMode }) => !isEditMode && 'none'};
  }

  // 수정모드일때 ItemText를 표시하지 않는다 (isEditMode === true)
  & ${ItemText} {
    display: ${({ isEditMode }) => isEditMode && 'none'};
  }
`;
const ReportViewMenuListItem = styled.li`
  height: 40px;
  display: flex;
  margin-top: 12px;

  // 읽기모드일때 아래 3개 컴포넌트를 표시하지 않는다 (isEditMode === false)
  & ${ItemInput} {
    display: ${({ isEditMode }) => !isEditMode && 'none'};
  }
  & ${SidePanelDropdownWrapper} {
    display: ${({ isEditMode }) => !isEditMode && 'none'};
  }
  & ${NumberInputWrapper} {
    display: ${({ isEditMode }) => !isEditMode && 'none'};
  }

  // 수정모드일때 ItemText를 표시하지 않는다 (isEditMode === true)
  & ${ItemText} {
    display: ${({ isEditMode }) => isEditMode && 'none'};
  }
`;

const ItemLabel = styled(Label)`
  width: 128px;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;
const HeaderLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const HeaderLabel = styled(Label)`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const Button = styled(CommonButton)`
  ${({ theme, color }) => {
    let textColor, borderColor, bgColor;
    switch (color) {
      case 'blackAndWhite':
        textColor = theme.color.COOL_GRAY_90;
        bgColor = theme.color.WHITE;
        borderColor = theme.color.COOL_GRAY_50;
        break;
      case 'primary':
        textColor = theme.color.BLUE_70;
        bgColor = theme.color.WHITE;
        borderColor = theme.color.BLUE_70;
        break;
      default:
        break;
    }
    return `
      color: ${textColor};
      background: ${bgColor};
      border: 1px solid ${borderColor};
    `;
  }};
  font-size: 12px;
  height: 28px;
`;

const StyledDropDownIcon = styled(DropDownIcon)`
  width: 16px;
  height: 16px;
`;
const StyledEditIcon = styled(EditIcon)`
  width: 20px;
  height: 20px;
`;
const StyledBlockedEditIcon = styled(BlockedEditIcon)`
  width: 20px;
  height: 20px;
`;
const StyledDeleteIcon = styled(DeleteIcon)`
  width: 20px;
  height: 20px;
`;
const StyledBlockedDeleteIcon = styled(BlockedDeletedIcon)`
  width: 20px;
  height: 20px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 20px;
  height: 20px;
`;
const StyledInformationPatientIcon = styled(InformationPatientIcon)`
  width: 16px;
  height: 16px;
`;
const StyledInformationMeasurementIcon = styled(InformationMeasurementIcon)`
  width: 16px;
  height: 16px;
`;
const StyledCommentIcon = styled(CommentIcon)`
  width: 16px;
  height: 16px;
`;
const StyledIconLinkOff = styled(IconLinkOff)`
  width: 16px;
  height: 16px;
`;

const { ACCESS_LEVEL, CHARACTERS_MAX_LENGTH } = Const;

function TableSidePanelFragment({
  // store states
  sidePanelState,
  ecgTestPatchState,
  authUserAccessLevelState,
  validReportPending,
  medicalStaffsFetchPending,
  // dispatches
  handleHideSidePanel,
  handleRevertStatusRequested,
  handleDeleteEcgTestRequested,
  handlePatchEcgTestRequested,
  handleShowDialog,
  loadPageData,
  handleReportDownloadStatusCheck,
  // local state
  isEditMode,
  setIsEditMode,
  isNoteEditMode,
  setIsNoteEditMode,
  emrUploadedDatetime,
}) {
  const intl = useIntl();
  const theme = useTheme();
  const { enqueueMessage } = useSnackbarStack();

  const {
    deviceNumber,
    ecgTestStatus,
    estimatedEndDatetime,
    internalConfirmDatetime,
    hookedUpBy,
    recordingTimeMs,
    analysisTimeMs,
    returnDatetime,
    startDatetime,
    parcel,
    tid,
    shortTid,
    noteModifiedDatetime,
    noteLastModifier,
    emrUploadedBy,
    isUploadedToEmr,
    startTimestamp,
    endTimestamp,
    isMemoCueEcgTest,
    //
    patientName: prevPatientName,
    patientNumber: prevPatientNumber,
    patientBirth: prevPatientBirth,
    patientSex: prevPatientSex,
    patientPhoneNumber: prevPatientPhoneNumber,
    pacemaker: prevPacemaker,
    prescriptionDuration: prevPrescriptionDuration,
    referredBy: prevReferredBy,
    note: prevNote,
  } = sidePanelState?.ecgTest || {};

  // local states
  // 환자 기본 정보
  const [patientName, setPatientName] = useState(prevPatientName || '');
  const [patientNumber, setPatientNumber] = useState(prevPatientNumber || '');
  const [patientBirth, setPatientBirth] = useState(prevPatientBirth || '');
  const [patientSex, setPatientSex] = useState(prevPatientSex || '');
  const [patientPhoneNumber, setPatientPhoneNumber] = useState(
    prevPatientPhoneNumber || ''
  );
  const [pacemaker, setPacemaker] = useState(prevPacemaker);
  // 검사 기본 정보
  const [prescriptionDuration, setPrescriptionDuration] = useState(
    prevPrescriptionDuration || 1
  );
  const [referredBy, setReferredBy] = useState(prevReferredBy);
  // 비고
  const [note, setNote] = useState(prevNote || '');

  const [hasDifferentValueInInfo, setHasDifferentValueInInfo] = useState(false);
  const [hasDifferentValueInNote, setHasDifferentValueInNote] = useState(false);
  const [hasDifferentValueInPhoneNumber, setHasDifferentValueInPhoneNumber] =
    useState(false);
  const [isAllInfoEntered, setIsAllInfoEntered] = useState(false);

  const [inputDisableStatus, setInputDisableStatus] = useState({
    patientName: false,
    patientNumber: false,
    patientBirth: false,
    patientSex: false,
    patientPhoneNumber: false,
    pacemakerInfo: false,
    prescriptionDuration: false,
    note: false,
  });

  // 상태에 따른 렌더링 기준이 되는 값
  const isAccessLevelReadOnly =
    authUserAccessLevelState === ACCESS_LEVEL.READ_ONLY.value;

  // custom
  const initDoctor = !prevReferredBy
    ? null
    : {
        key: prevReferredBy?.username ?? '',
        label: prevReferredBy?.firstName ?? '',
        value: prevReferredBy?.username ?? '',
      };
  const [dropdownReferredBy, DoctorsDropdown] = useDoctorsDropdown(
    intl.formatMessage(INTL_MAP.DOCTORS_DROPDOWN_SELECT_REFERRED_BY),
    initDoctor,
    { disabled: inputDisableStatus.referredBy }
  );

  const recordingDuration = recordingTimeMs
    ? DateUtil.intervalToDuration({
        start: 0,
        end: recordingTimeMs,
      })
    : '';

  const analyzedDuration = analysisTimeMs
    ? DateUtil.intervalToDuration({
        start: 0,
        end: analysisTimeMs,
      })
    : '';

  // 처방일수를 ms로 변환
  const prescriptionDurationMs = DateUtil.convertDateToMs(prescriptionDuration);

  // 처방일수 대비 실제 측정기간 비율
  const actualPrescriptionDuration = DateUtil.calculatePercentage(Math.floor, {
    numerator: recordingTimeMs,
    denominator: prescriptionDurationMs,
  });

  // useEffect
  const initializePatientInfo = useCallback(() => {
    setPatientName(prevPatientName || '');
    setPatientNumber(prevPatientNumber || '');
    setPatientBirth(prevPatientBirth || '');
    setPatientSex(prevPatientSex || '');
    setPatientPhoneNumber(
      NumberUtil.getPhoneNumberFormat(prevPatientPhoneNumber || '')
    );
    setPacemaker(prevPacemaker);
    setPrescriptionDuration(prevPrescriptionDuration || '');
    setNote(prevNote || '');
    setReferredBy(prevReferredBy || null);
  }, [sidePanelState]);
  const prevEcgTestId = usePrevious(tid);
  useEffect(() => {
    if (!sidePanelState.ecgTest) return;
    if (prevEcgTestId === tid) return; // 이전 tid와 같을 경우 새로고침하지 않는다
    // 이유: 수정모드에서 pooling이 이루어지면 작성중 내용이 날아가면서 읽기모드로 변경되는것을 방지하기 위해

    setIsEditMode(false);
    initializePatientInfo();
  }, [sidePanelState]);

  // 검사정보/비고 업데이트 여부를 snackbar를 통해 알려준다.
  const prevEcgTestPatchState = usePrevious(ecgTestPatchState);
  useEffect(() => {
    if (
      prevEcgTestPatchState &&
      prevEcgTestPatchState.pending &&
      !ecgTestPatchState.pending
    ) {
      if (ecgTestPatchState.error) return;

      loadPageData();

      const isTestDataUpdated =
        hasDifferentValueInInfo || hasDifferentValueInNote;

      const shouldPhoneNumberSnackbarVisible =
        Const.ECG_TEST_STATUS.BEFORE_TEST <=
          ecgTestPatchState.data.ecgTestStatus &&
        hasDifferentValueInPhoneNumber;

      if (isTestDataUpdated) {
        if (shouldPhoneNumberSnackbarVisible) {
          enqueueMessage(
            intl.formatMessage(
              INTL_MAP.SNACKBAR_SAVE_PATIENT_INFO_WITHOUT_PHONE_NUMBER
            )
          );
        } else {
          enqueueMessage(
            intl.formatMessage(INTL_MAP.SNACKBAR_SAVE_PATIENT_INFO)
          );
        }

        setHasDifferentValueInInfo(false);
        setHasDifferentValueInPhoneNumber(false);

        if (hasDifferentValueInNote) {
          setHasDifferentValueInNote(false);
        }
      }

      setIsEditMode(false);
      initializePatientInfo();
    }
  }, [ecgTestPatchState]);

  useEffect(() => {
    debouncedInfoValidation();
  }, [
    patientName,
    patientNumber,
    patientBirth,
    patientSex,
    patientPhoneNumber,
    pacemaker,
    dropdownReferredBy,
    prescriptionDuration,
    note,
  ]);

  const debouncedInfoValidation = debounce(() => {
    const resultPatientName = haveDifferentWords(prevPatientName, patientName);
    const resultPatientId = haveDifferentWords(
      prevPatientNumber,
      patientNumber
    );
    const resultGender = haveDifferentWords(prevPatientSex, patientSex);
    const resultBirthDate = haveDifferentWords(prevPatientBirth, patientBirth);
    const resultPhoneNumber = haveDifferentWords(
      NumberUtil.getPhoneNumberFormat(prevPatientPhoneNumber || ''),
      NumberUtil.getPhoneNumberFormat(patientPhoneNumber || '')
    );
    const resultPrescriptionDuration = haveDifferentWords(
      prevPrescriptionDuration,
      prescriptionDuration
    );
    const resultPacemaker = haveDifferentWords(prevPacemaker, pacemaker);

    const resultReferredBy = dropdownReferredBy
      ? haveDifferentWords(prevReferredBy?.firstName, dropdownReferredBy?.label)
      : false;
    const resultNote = haveDifferentWords(prevNote, note);

    setHasDifferentValueInInfo(
      resultPatientName ||
        resultPatientId ||
        resultGender ||
        resultBirthDate ||
        resultPhoneNumber ||
        resultPrescriptionDuration ||
        resultPacemaker ||
        resultReferredBy
    );
    setHasDifferentValueInNote(resultNote);
    setHasDifferentValueInPhoneNumber(resultPhoneNumber);
    setIsAllInfoEntered(
      !!(patientNumber && patientBirth && patientSex && prescriptionDuration)
    );
  }, 300);

  useEffect(() => {
    /**
     * 검사 상태에 따라 입력 필드의 사용가능 여부를 체크
     */
    const checkInputDisableStatus = () => {
      const { CUSTOMER_CONFIRMED, UNDER_CLOUD, ECG_UPLOADED } =
        Const.ECG_TEST_STATUS;

      const isTestConfirmed = ecgTestStatus >= CUSTOMER_CONFIRMED;
      const isUnderCloud =
        ECG_UPLOADED <= ecgTestStatus && ecgTestStatus <= UNDER_CLOUD;

      const isTestStarted = Const.ECG_TEST_STATUS.BEFORE_TEST < ecgTestStatus;

      setInputDisableStatus({
        ...inputDisableStatus,
        patientName: isTestConfirmed,
        patientNumber: isTestConfirmed,
        patientBirth: isTestConfirmed,
        patientSex: isTestConfirmed,
        patientPhoneNumber: isTestStarted,
        referredBy: isTestConfirmed,
        prescriptionDuration: isTestConfirmed || isUnderCloud,
      });
    };

    checkInputDisableStatus();
  }, [ecgTestStatus]);

  // onChange 함수
  const onChangeNoteTextArea = (event) => {
    if (event.target.value.length > CHARACTERS_MAX_LENGTH.NOTE) return;
    event.nativeEvent.trigger = true;
    setNote(event.target.value);
  };

  const onChangePatientBirth = ({ target, nativeEvent: { inputType } }) => {
    const text = target.value;
    const regex = /^[0-9\*\b -]{0,10}$/;
    if (regex.test(text)) {
      setPatientBirth(() => {
        if (inputType === 'insertFromPaste') {
          return text
            .replace(/-/g, '')
            .replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        } else {
          return text
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, '$1-$2-$3')
            .replace(/(-{1,2})$/g, '');
        }
      });
    }
  };

  const onChangePatientPhoneNumber = ({
    target,
    nativeEvent: { inputType },
  }) => {
    const text = target.value;
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(text)) {
      setPatientPhoneNumber(() => {
        if (inputType === 'insertFromPaste') {
          return NumberUtil.getPhoneNumberFormatByPaste(text);
        } else {
          return NumberUtil.getPhoneNumberFormat(text);
        }
      });
    }
  };

  const onChangePatientName = (event) => setPatientName(event.target.value);

  const onChangePatientNumber = (event) => setPatientNumber(event.target.value);

  const onChangePrescriptionDuration = (text) => {
    const testDuration = getParsedTestDuration(text);

    if (testDuration == null) return;

    if (
      parcel?.id !== null &&
      parcel?.status !== PARCEL_STATUS.CANCELED.value &&
      startDatetime
    ) {
      const pickUpDate = DateUtil.getUserLocationTime({
        date: parcel.pickUpDate,
      }).setHours(0, 0, 0, 0);
      const estimatedEndDate = DateUtil.addDays(
        DateUtil.getUserLocationTime({ date: startDatetime }),
        testDuration
      ).setHours(0, 0, 0, 0);

      /**
       * 1. (변경하는 처방일수에 따른 검사 종료일이 택배 수거일 이전인 경우) 변경 가능, 택배는 최초 설정한 일자에 수거
       * 2. (변경하는 처방일수에 따른 검사 종료일이 택배 수거일 이후인 경우) 단순변경 불가, "처방 완료일이 택배 수거일보다 늦어 변경이 어렵습니다. 변경을 원하시면 CS센터로 연락해주세요." 팝업 출력
       **/
      if (estimatedEndDate >= pickUpDate) {
        handleShowDialog({
          dialogKey: 'AlertDialog',
          params: {
            message:
              '처방 완료일이 택배 수거일보다 늦어 변경이 어렵습니다.\n변경을 원하시면 CS센터로 연락해주세요',
          },
        });
        setPrescriptionDuration(prevPrescriptionDuration);
        return;
      }
    }

    setPrescriptionDuration(testDuration);
  };

  const onClickSave = () => {
    if (resultOfValidateBeforeSave()) return;

    handleShowDialog({
      dialogKey: 'ConfirmDialog',
      params: {
        message: intl.formatMessage(INTL_MAP.CONFIRM_DIALOG_SAVE_MESSAGE),
        confirmButtonText: intl.formatMessage(
          INTL_MAP.CONFIRM_DIALOG_SAVE_CONFIRM
        ),
        confirmButtonColor: theme.color.BLUE_70,
        cancelButtonText: intl.formatMessage(
          INTL_MAP.CONFIRM_DIALOG_SAVE_CANCEL
        ),
        cancelButtonColor: theme.color.COOL_GRAY_80,
        onSubmit: () => {
          handlePatchEcgTestRequested({
            ecgTestId: tid,
            patientInfo: {
              patientName: patientName,
              patientNumber: patientNumber,
              patientBirth: patientBirth,
              patientSex: patientSex,
              patientPhoneNumber: patientPhoneNumber.replace(/[^\d]/g, ''),
              pacemaker,
              referredBy: dropdownReferredBy
                ? dropdownReferredBy.value
                : undefined,
              prescriptionDuration,
              note,
            },
          });
        },
      },
    });
  };
  const resultOfValidateBeforeSave = () => {
    const validationList = [
      validationBirthDay(patientBirth, { intl }),
      validationPhoneNumber(patientPhoneNumber, { intl }),
      validationPrescribedBy(dropdownReferredBy, { intl }),
    ];

    for (let validation of validationList) {
      let validationResult = validation;
      if (validationResult.result === true) {
        handleShowDialog({
          dialogKey: 'AlertDialog',
          params: {
            message: validationResult.msg,
          },
        });
        return true;
      }
    }

    return false;
  };

  // 내부에서 사용되는 components
  const $editButton = ({ isMemoCueEcgTest }) => {
    if (isAccessLevelReadOnly || isEditMode) {
      return;
    }

    const onClickEditButton = () => {
      if (isMemoCueEcgTest) return;
      if (isEditMode) {
        setIsNoteEditMode(false);
        initializePatientInfo();
      }

      setIsEditMode((prev) => !prev);
    };
    const renderIconWrapper = () => (
      <IconWrapper
        isMemoCueEcgTest={isMemoCueEcgTest}
        onClick={onClickEditButton}
        data-testid={DATA_TEST_ID.TABLE_SIDE_PANEL.BUTTON.EDIT}>
        {isMemoCueEcgTest ? <StyledBlockedEditIcon /> : <StyledEditIcon />}
      </IconWrapper>
    );

    return isMemoCueEcgTest ? (
      <Tooltip
        placement={'bottom'}
        title={intl.formatMessage(INTL_MAP.TOOLTIP_BUTTON_BLOCKED_EDIT)}>
        {renderIconWrapper()}
      </Tooltip>
    ) : (
      renderIconWrapper()
    );
  };

  const $deleteButton = ({ isMemoCueEcgTest }) => {
    if (
      isAccessLevelReadOnly ||
      isEditMode ||
      Const.ECG_TEST_STATUS.BEFORE_TEST < ecgTestStatus
    ) {
      return;
    }

    const onClickDeleteButton = () => {
      if (isMemoCueEcgTest) return;
      handleShowDialog({
        dialogKey: 'ConfirmDialog',
        params: {
          title: intl.formatMessage(INTL_MAP.CONFIRM_DIALOG_DELETE_TITLE, {
            patientName,
          }),
          message: intl.formatMessage(INTL_MAP.CONFIRM_DIALOG_DELETE_SUBTITLE),
          confirmButtonText: intl.formatMessage(
            INTL_MAP.CONFIRM_DIALOG_DELETE_CONFIRM
          ),
          confirmButtonColor: theme.color.RED_70,
          onSubmit: () => {
            handleDeleteEcgTestRequested({
              ecgTestId: tid,
            });
          },
        },
      });
    };
    // BlockedDeletedIcon
    const renderIconWrapper = () => (
      <IconWrapper
        isMemoCueEcgTest={isMemoCueEcgTest}
        onClick={onClickDeleteButton}
        data-testid={DATA_TEST_ID.TABLE_SIDE_PANEL.BUTTON.DELETE}>
        {isMemoCueEcgTest ? <StyledBlockedDeleteIcon /> : <StyledDeleteIcon />}
      </IconWrapper>
    );

    return isMemoCueEcgTest ? (
      <Tooltip
        placement={'bottom'}
        title={intl.formatMessage(INTL_MAP.TOOLTIP_BUTTON_BLOCKED_EDIT)}>
        {renderIconWrapper()}
      </Tooltip>
    ) : (
      renderIconWrapper()
    );
  };

  const $dataItem = () => {
    const isBeforeReview = ecgTestStatus <= Const.ECG_TEST_STATUS.UNDER_CLOUD;
    const hasValidEcgTest = sidePanelState.ecgTest;

    if (isBeforeReview || !hasValidEcgTest) return null;

    return (
      <MenuGroup
        style={{
          paddingBottom: 7,
        }}>
        <EcgTestDataItem
          ecgTest={sidePanelState.ecgTest}
          validReportPending={validReportPending}
          medicalStaffsFetchPending={medicalStaffsFetchPending}
          isSidePanel={true}
          handleRevertStatusRequested={handleRevertStatusRequested}
          handleShowDialog={handleShowDialog}
          loadPageData={loadPageData}
        />
      </MenuGroup>
    );
  };

  return (
    <Wrapper>
      <DrawerBackdrop
        onClick={() => handleHideSidePanel()}
        open={sidePanelState.isOpen}
        invisible={true}
      />
      <SidePanelDrawer isOpen={sidePanelState.isOpen}>
        <SidePanelHeader>
          <HeaderLabelWrapper>
            <HeaderLabel
              text={intl.formatMessage(INTL_MAP.SIDEPANEL_HEADER_TITLE)}
            />
            {isMemoCueEcgTest && (
              <EcgTestFromCueWrapper isSidePanelWrapper={true}>
                <EcgTestFromCueText>Cue에서 생성된 검사</EcgTestFromCueText>
              </EcgTestFromCueWrapper>
            )}
          </HeaderLabelWrapper>

          {$editButton({ isMemoCueEcgTest })}
          {$deleteButton({ isMemoCueEcgTest })}
          <Tooltip
            placement={'bottom'}
            title={intl.formatMessage(INTL_MAP.TOOLTIP_BUTTON_CLOSE)}>
            <IconWrapper
              onClick={() => handleHideSidePanel()}
              data-testid={DATA_TEST_ID.TABLE_SIDE_PANEL.BUTTON.CLOSE}>
              <StyledCloseIcon />
            </IconWrapper>
          </Tooltip>
        </SidePanelHeader>
        <SidePanelContent>
          {$dataItem()}
          <MenuGroup>
            <MenuList
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
              }}>
              <StyledInformationPatientIcon />
              <MenuTitle>
                {intl.formatMessage(INTL_MAP.MENU_TITLE_PATIENT_INFO)}
              </MenuTitle>
            </MenuList>
            <MenuList>
              <MenuListItem isEditMode={isEditMode}>
                <ItemLabel text={intl.formatMessage(INTL_MAP.PATIENT_NAME)} />
                <ItemInput
                  data-testid={DATA_TEST_ID.TABLE_SIDE_PANEL.PATIENT.NAME}
                  value={patientName}
                  disabled={inputDisableStatus.patientName}
                  onChange={onChangePatientName}
                />
                <ItemText>{patientName}</ItemText>
              </MenuListItem>
              <MenuListItem isEditMode={isEditMode}>
                <ItemLabel text={intl.formatMessage(INTL_MAP.PATIENT_NUMBER)} />
                <ItemInput
                  data-testid={DATA_TEST_ID.TABLE_SIDE_PANEL.PATIENT.NUMBER}
                  value={patientNumber}
                  disabled={inputDisableStatus.patientNumber}
                  onChange={onChangePatientNumber}
                />
                <ItemText>{patientNumber}</ItemText>
              </MenuListItem>
              <MenuListItem isEditMode={isEditMode}>
                <ItemLabel text={intl.formatMessage(INTL_MAP.PATIENT_BIRTH)} />
                <ItemInput
                  data-testid={DATA_TEST_ID.TABLE_SIDE_PANEL.PATIENT.BIRTH_DATE}
                  value={patientBirth}
                  disabled={inputDisableStatus.patientBirth}
                  placeholder="YYYY-MM-DD"
                  onChange={onChangePatientBirth}
                />
                <ItemText>{patientBirth}</ItemText>
              </MenuListItem>
              <MenuListItem isEditMode={isEditMode}>
                <ItemLabel text={intl.formatMessage(INTL_MAP.PATIENT_SEX)} />
                <SidePanelDropdownWrapper
                  data-testid={DATA_TEST_ID.TABLE_SIDE_PANEL.PATIENT.SEX}>
                  <SidePanelDropdown
                    item={
                      patientSex === 'M'
                        ? genderInfo[0].label
                        : genderInfo[1].label
                    }
                    onClickItem={setPatientSex}
                    itemList={genderInfo}
                    disabled={inputDisableStatus.patientSex}
                  />
                </SidePanelDropdownWrapper>
                <ItemText>
                  {patientSex === 'M'
                    ? genderInfo[0].label
                    : genderInfo[1].label}
                </ItemText>
              </MenuListItem>
              <MenuListItem isEditMode={isEditMode}>
                <ItemLabel
                  text={intl.formatMessage(INTL_MAP.PATIENT_PHONE_NUMBER)}
                />
                <ItemInput
                  data-testid={
                    DATA_TEST_ID.TABLE_SIDE_PANEL.PATIENT.PHONE_NUMBER
                  }
                  value={patientPhoneNumber}
                  disabled={inputDisableStatus.patientPhoneNumber}
                  onChange={onChangePatientPhoneNumber}
                />
                <ItemText>{patientPhoneNumber}</ItemText>
              </MenuListItem>
              <MenuListItem>
                <ItemLabel text={intl.formatMessage(INTL_MAP.DEVICE_NUMBER)} />
                <ItemText>{deviceNumber || '-'}</ItemText>
              </MenuListItem>
              <MenuListItem isEditMode={isEditMode}>
                <ItemLabel text={intl.formatMessage(INTL_MAP.PACEMAKER)} />
                <SidePanelDropdownWrapper
                  data-testid={DATA_TEST_ID.TABLE_SIDE_PANEL.PATIENT.PACEMAKER}>
                  <SidePanelDropdown
                    item={
                      pacemakerInfo.find((item) => item.value === pacemaker)
                        ?.label
                    }
                    onClickItem={(item) => setPacemaker(item)}
                    itemList={pacemakerInfo}
                  />
                </SidePanelDropdownWrapper>
                <ItemText>
                  {
                    pacemakerInfo.find((item) => item.value === pacemaker)
                      ?.label
                  }
                </ItemText>
              </MenuListItem>
            </MenuList>
          </MenuGroup>
          <MenuGroup>
            <MenuList
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
              }}>
              <StyledInformationMeasurementIcon />
              <MenuTitle>
                {intl.formatMessage(INTL_MAP.MENU_TITLE_TEST_INFO)}
              </MenuTitle>
            </MenuList>
            <MenuList>
              <MenuListItem>
                <ItemLabel text={intl.formatMessage(INTL_MAP.SHORT_ID)} />
                <ItemText>{shortTid}</ItemText>
              </MenuListItem>
              <MenuListItem isEditMode={isEditMode}>
                <ItemLabel text={intl.formatMessage(INTL_MAP.REFERRED_BY)} />
                <SidePanelDropdownWrapper
                  data-testid={DATA_TEST_ID.TABLE_SIDE_PANEL.TEST.REFERRED_BY}>
                  <DoctorsDropdown
                    anchorContainerStyle={{
                      width: 214,
                      height: 26,
                      padding: '0px 8px 0px 12px',
                    }}
                    itemContainerStyle={{
                      top: '32px',
                    }}
                    disabled={inputDisableStatus.referredBy}
                  />
                </SidePanelDropdownWrapper>
                <ItemText>{referredBy?.firstName || '-'}</ItemText>
              </MenuListItem>
              <MenuListItem isEditMode={isEditMode}>
                <ItemLabel
                  text={intl.formatMessage(INTL_MAP.PRESCRIPTION_DURATION)}
                />
                <NumberInputWrapper
                  data-testid={
                    DATA_TEST_ID.TABLE_SIDE_PANEL.TEST.PRESCRIPTION_DURATION
                  }>
                  <TextInput
                    style={{
                      width: 234,
                      height: 26,
                      display: 'flex',
                      border: `1px solid ${theme.color.COOL_GRAY_40}`,
                    }}
                    disabled={inputDisableStatus.prescriptionDuration}
                    value={prescriptionDuration}
                    onChange={onChangePrescriptionDuration}
                  />
                </NumberInputWrapper>
                <ItemText>{prescriptionDuration}</ItemText>
              </MenuListItem>
              <MenuListItem>
                <ItemLabel text={intl.formatMessage(INTL_MAP.HOOKED_UP_BY)} />
                <ItemText>{hookedUpBy?.firstName || 'N/A'}</ItemText>
              </MenuListItem>
              <MenuListItem>
                <ItemLabel text={intl.formatMessage(INTL_MAP.START_DATETIME)} />
                <ItemText>
                  {startTimestamp
                    ? DateUtil.formatDateTime(startTimestamp)
                    : startDatetime
                    ? DateUtil.formatDateTime(startDatetime)
                    : '-'}
                </ItemText>
              </MenuListItem>
              <MenuListItem>
                <ItemLabel
                  text={intl.formatMessage(INTL_MAP.ESTIMATED_END_DATETIME)}
                />
                <ItemText>
                  <EstimatedEndAndReturnDateTime
                    estimatedEndDatetime={estimatedEndDatetime}
                    returnDatetime={returnDatetime}
                    endTimestamp={endTimestamp}
                    dateFormat={'yyyy-MM-dd (HH:mm:ss)'}
                  />
                </ItemText>
              </MenuListItem>
              <MenuListItem>
                <ItemLabel
                  text={intl.formatMessage(INTL_MAP.ANALYSIS_END_DATE)}
                />
                <ItemText>
                  {internalConfirmDatetime
                    ? DateUtil.formatDateOnly(internalConfirmDatetime)
                    : '-'}
                </ItemText>
              </MenuListItem>
              <MenuListItem>
                <ItemLabel text={intl.formatMessage(INTL_MAP.RECORDING_TIME)} />
                <ItemText>
                  {intl.formatMessage(INTL_MAP.RECORDING_TIME_DURATION, {
                    days: recordingDuration.days,
                    hours: recordingDuration.hours,
                    minutes: recordingDuration.minutes,
                  }) +
                    (recordingTimeMs && actualPrescriptionDuration
                      ? ` (${actualPrescriptionDuration}%)`
                      : ' ( - )')}
                </ItemText>
              </MenuListItem>
              <MenuListItem>
                <ItemLabel text={intl.formatMessage(INTL_MAP.ANALYSIS_TIME)} />
                <ItemText>
                  {analysisTimeMs
                    ? [
                        intl.formatMessage(INTL_MAP.ANALYSIS_TIME_DURATION, {
                          days: analyzedDuration.days,
                          hours: analyzedDuration.hours,
                          minutes: analyzedDuration.minutes,
                        }),
                        <ItemGap key="gap" />,
                        <ItemHighLightText key="highlight">
                          Lead-off excluded
                        </ItemHighLightText>,
                      ]
                    : '-'}
                </ItemText>
              </MenuListItem>
              <ReportViewMenuListItem>
                <LabelContentWrapper>
                  <ItemLabel
                    text={intl.formatMessage(
                      INTL_MAP.REPORT_DOWNLOAD_STATUS_CHECK
                    )}
                  />
                  <RollbackDownloadWrapper>
                    {isUploadedToEmr && emrUploadedBy ? (
                      <>
                        <div style={{ width: 140 }}>
                          {intl.formatMessage(
                            INTL_MAP.REPORT_LAST_DOWNLOAD_PERSON_DATETIME,
                            {
                              Viewer: emrUploadedBy.firstName,
                            }
                          )}
                          {`, ${DateUtil.formatDateOnly(
                            emrUploadedDatetime ?? 0
                          )}`}
                        </div>
                        <ItemGap key="gap" />
                        <RollbackDownload
                          onClick={() => {
                            handleReportDownloadStatusCheck(false);
                          }}>
                          <StyledIconLinkOff />

                          {intl.formatMessage(
                            INTL_MAP.REPORT_LAST_DOWNLOAD_UNDO_COMPLETION_BUTTON
                          )}
                        </RollbackDownload>
                      </>
                    ) : (
                      <>
                        {intl.formatMessage(
                          INTL_MAP.REPORT_LAST_DOWNLOAD_PERSON_DATETIME_UN_VIEWED
                        )}
                      </>
                    )}
                  </RollbackDownloadWrapper>
                </LabelContentWrapper>
              </ReportViewMenuListItem>
            </MenuList>
          </MenuGroup>
        </SidePanelContent>
        <SidePanelComment>
          <MenuList
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
            }}>
            <StyledCommentIcon />
            <MenuTitle>
              {intl.formatMessage(INTL_MAP.MENU_TITLE_COMMENT, {
                length: note.length,
                maxLength: CHARACTERS_MAX_LENGTH.NOTE,
              })}
            </MenuTitle>
            {noteLastModifier?.firstName && noteModifiedDatetime && (
              <Tooltip
                placement={'bottom'}
                title={intl.formatMessage(INTL_MAP.NOTE_UPDATED_BY, {
                  lastModifier: noteLastModifier.firstName,
                  modifiedDate: DateUtil.format(
                    noteModifiedDatetime,
                    'yyyy-MM-dd (HH:mm)'
                  ),
                  br: <br />,
                })}
                maxWidth={150}>
                <MenuTitleHelperText>
                  {intl.formatMessage(INTL_MAP.NOTE_UPDATED_BY, {
                    lastModifier: noteLastModifier.firstName,
                    modifiedDate: DateUtil.format(
                      noteModifiedDatetime,
                      'yyyy-MM-dd (HH:mm)'
                    ),
                    br: ' ',
                  })}
                </MenuTitleHelperText>
              </Tooltip>
            )}
          </MenuList>
          <MenuListItem>
            <TextAreaInput
              dataTestId={DATA_TEST_ID.TABLE_SIDE_PANEL.TEST.NOTE}
              resize="none"
              style={{
                width: '100%',
              }}
              disabled={isAccessLevelReadOnly}
              height={128}
              value={note}
              onFocus={() => {
                if (isAccessLevelReadOnly) return;
                setIsNoteEditMode(true);
              }}
              onChange={onChangeNoteTextArea}
            />
          </MenuListItem>
        </SidePanelComment>
        {(isEditMode || isNoteEditMode) && (
          <SidePanelFooter>
            <Button
              dataTestId={DATA_TEST_ID.TABLE_SIDE_PANEL.BUTTON.CANCEL}
              onClick={() => {
                setIsEditMode(false);
                setIsNoteEditMode(false);
                initializePatientInfo();
              }}
              color={'blackAndWhite'}
              outline
              title={intl.formatMessage(INTL_MAP.FOOTER_BUTTON_CANCEL)}
            />
            <Button
              dataTestId={DATA_TEST_ID.TABLE_SIDE_PANEL.BUTTON.SAVE}
              outline
              disabled={
                !isAllInfoEntered ||
                (!hasDifferentValueInInfo && !hasDifferentValueInNote)
              }
              title={intl.formatMessage(INTL_MAP.FOOTER_BUTTON_SAVE)}
              onClick={onClickSave}
            />
          </SidePanelFooter>
        )}
      </SidePanelDrawer>
    </Wrapper>
  );
}

export default TableSidePanelFragment;

const SidePanelDropdown = ({
  item,
  onClickItem,
  itemList,
  anchorProps,
  disabled,
}) => {
  return (
    <DropdownBase
      anchorProps={{
        ...anchorProps,
        style: {
          width: 236,
          height: 28,
          padding: '4px 4px 4px 12px',
          cursor: disabled && 'auto',
        },
      }}
      disabled={disabled}
      contentCloseReason="default"
      dropdownDirection="left"
      label={
        <LabelWrapper disabled={disabled}>
          <LabelText>{item}</LabelText>
          <StyledDropDownIcon />
        </LabelWrapper>
      }>
      <MenuBase style={{ width: 226 }}>
        {itemList.map((item, index) => (
          <MenuItem
            key={`${item}_${index}`}
            title={item.label}
            onClick={() => {
              onClickItem(item.value);
            }}
          />
        ))}
      </MenuBase>
    </DropdownBase>
  );
};

/**
 * - SIDEPANEL_HEADER_TITLE: 기본정보
 * - CONFIRM_DIALOG_SAVE_MESSAGE: 환자 정보를 변경하시겠습니까?
 * - CONFIRM_DIALOG_SAVE_CONFIRM: 변경하기
 * - CONFIRM_DIALOG_SAVE_CANCEL: 취소
 * - SNACKBAR_SAVE_PATIENT_INFO: 환자 정보가 변경되었습니다.
 * - SNACKBAR_SAVE_NOTE: 비고가 수정되었습니다.
 * - DOCTORS_DROPDOWN_SELECT_REFERRED_BY: 처방의 선택
 * - CONFIRM_DIALOG_DELETE_TITLE: {patientName}님의 검사를 삭제하시겠습니까?
 * - CONFIRM_DIALOG_DELETE_SUBTITLE : 삭제 후에는 복구할 수 없습니다.
 * - CONFIRM_DIALOG_DELETE_CONFIRM: 삭제
 * - MENU_TITLE_PATIENT_INFO: 환자 기본 정보
 * - MENU_TITLE_TEST_INFO: 검사 기본 정보
 * - MENU_TITLE_COMMENT: 비고 {length}/{maxLength}
 * - PATIENT_NAME: 환자명
 * - PATIENT_NUMBER: 환자번호
 * - PATIENT_BIRTH: 생년월일
 * - PATIENT_SEX: 성별
 * - PATIENT_PHONE_NUMBER: 전화번호
 * - DEVICE_NUMBER: 패치 일련번호
 * - PACEMAKER: 페이스메이커
 * - SHORT_ID: 검사번호
 * - PRESCRIPTION_DURATION: 처방기간
 * - START_DATETIME: 검사 시작일
 * - ESTIMATED_END_DATETIME: 검사 종료일
 * - REFERRED_BY: 처방의
 * - HOOKED_UP_BY: 패치 부착 담당자
 * - RECORDING_TIME: 측정 기간
 * - ANALYSIS_TIME: 분석 대상 기간
 * - RECORDING_TIME_DURATION: {days}일 {hours}시간 {minutes}분
 * - ANALYSIS_TIME_DURATION: {days}일 {hours}시간 {minutes}분
 * - REPORT_DOWNLOAD_STATUS_CHECK: 리포트 조회/다운로드
 * - FOOTER_BUTTON_SAVE: 저장
 * - FOOTER_BUTTON_CANCEL: 취소
 * - TOOLTIP_BUTTON_CLOSE: 닫기: (esc) 누르기
 * - NOTE_UPDATED_BY: Updated By : {lastModifier}{br}{modifiedDate}
 */
const INTL_MAP = defineMessages({
  SIDEPANEL_HEADER_TITLE: {
    id: '03-EcgTestListFragment-TableSidePanel-sidePanelHeader-title',
    description: '기본정보',
    defaultMessage: '기본정보',
  },
  CONFIRM_DIALOG_SAVE_MESSAGE: {
    id: '99-ConfirmDialog-TableSidePanel-Save-01',
    description:
      '이 검사 정보를 변경하시겠습니까? / Are you sure you want to modify this test information?',
    defaultMessage: '이 검사 정보를 변경하시겠습니까?',
  },
  CONFIRM_DIALOG_SAVE_CONFIRM: {
    id: '99-ConfirmDialog-TableSidePanel-Save-02',
    description: '변경하기',
    defaultMessage: '변경하기',
  },
  CONFIRM_DIALOG_SAVE_CANCEL: {
    id: '99-ConfirmDialog-TableSidePanel-Save-03',
    description: '취소',
    defaultMessage: '취소',
  },
  SNACKBAR_SAVE_PATIENT_INFO: {
    id: '99-Snackbar-TableSidePanel-Saved-01',
    description:
      '검사 정보가 변경되었습니다. / The test information has been updated.',
    defaultMessage: '검사 정보가 변경되었습니다.',
  },
  SNACKBAR_SAVE_NOTE: {
    id: '99-Snackbar-TableSidePanel-Saved-02',
    description: '비고가 수정되었습니다.',
    defaultMessage: '비고가 수정되었습니다.',
  },
  SNACKBAR_SAVE_PATIENT_INFO_WITHOUT_PHONE_NUMBER: {
    id: '99-Snackbar-TableSidePanel-Saved-03',
    description:
      '전화번호를 제외한 환자 정보가 변경되었습니다. \n측정 시작 이후에는 전화번호를 변경할 수 없습니다. / The information has been updated, except for the phone number. \nOnce the test status is changed to "In Progress”, the phone number cannot be changed.',
    defaultMessage:
      '전화번호를 제외한 환자 정보가 변경되었습니다. \n측정 시작 이후에는 전화번호를 변경할 수 없습니다.',
  },
  DOCTORS_DROPDOWN_SELECT_REFERRED_BY: {
    id: '99-AddNewTestDialog-Dropdown-placeholder-referredBy',
    description: '3.4 검사 추가 팝업의 referredBy 필드 placeholder',
    defaultMessage: '처방의 선택',
  },
  CONFIRM_DIALOG_DELETE_TITLE: {
    id: '99-ConfirmDialog-TableRow-onClick-01',
    description: '홍길동님의 검사를 삭제하시겠습니까?',
    defaultMessage: '{patientName}님의 검사를 삭제하시겠습니까?',
  },
  CONFIRM_DIALOG_DELETE_SUBTITLE: {
    id: '99-ConfirmDialog-TableRow-onClick-02',
    description: '삭제 후에는 복구할 수 없습니다.',
    defaultMessage: '삭제 후에는 복구할 수 없습니다.',
  },
  CONFIRM_DIALOG_DELETE_CONFIRM: {
    id: '99-ConfirmDialog-TableRow-onClick-03',
    description: '삭제',
    defaultMessage: '삭제',
  },
  MENU_TITLE_PATIENT_INFO: {
    id: '03-EcgTestListFragment-TableSidePanel-menuTitles-01',
    description: '환자 기본 정보',
    defaultMessage: '환자 기본 정보',
  },
  MENU_TITLE_TEST_INFO: {
    id: '03-EcgTestListFragment-TableSidePanel-menuTitles-02',
    description: '검사 기본 정보',
    defaultMessage: '검사 기본 정보',
  },
  MENU_TITLE_COMMENT: {
    id: '03-EcgTestListFragment-TableSidePanel-menuTitles-03',
    description: '비고 7/2000',
    defaultMessage: '비고 {length}/{maxLength}',
  },
  PATIENT_NAME: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-patientName',
    description: '환자명',
    defaultMessage: '환자명',
  },
  PATIENT_NUMBER: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-patientNumber',
    description: '환자번호',
    defaultMessage: '환자번호',
  },
  PATIENT_BIRTH: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-patientBirth',
    description: '생년월일',
    defaultMessage: '생년월일',
  },
  PATIENT_SEX: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-patientSex',
    description: '성별',
    defaultMessage: '성별',
  },
  PATIENT_PHONE_NUMBER: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-patientPhoneNumber',
    description: '전화번호',
    defaultMessage: '전화번호',
  },
  DEVICE_NUMBER: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-deviceNumber',
    description: '패치 일련번호',
    defaultMessage: '패치 일련번호',
  },
  PACEMAKER: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-pacemaker',
    description: '페이스메이커',
    defaultMessage: '페이스메이커',
  },
  SHORT_ID: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-shortId',
    description: '검사번호',
    defaultMessage: '검사번호',
  },
  PRESCRIPTION_DURATION: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-prescriptionDuration',
    description: '처방일수 / Test Duration',
    defaultMessage: '처방일수',
  },
  START_DATETIME: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-startDatetime',
    description: '검사 시작일',
    defaultMessage: '검사 시작일',
  },
  ESTIMATED_END_DATETIME: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-estimatedEndDatetime',
    description: '검사 종료일',
    defaultMessage: '검사 종료일',
  },
  ANALYSIS_END_DATE: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-analysisEndDate',
    description: '분석 완료일 / Analysis End Date',
    defaultMessage: '분석 완료일',
  },
  REFERRED_BY: {
    id: '09-GeneralFragment-SectionVerticalItem-label-referredBy',
    description: '처방의',
    defaultMessage: '처방의',
  },
  HOOKED_UP_BY: {
    id: '09-GeneralFragment-SectionVerticalItem-label-hookedUpBy',
    description: '패치 부착 담당자',
    defaultMessage: '패치 부착 담당자',
  },
  RECORDING_TIME: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-recordingTime-label',
    description: '실 측정기간',
    defaultMessage: '실 측정기간',
  },
  ANALYSIS_TIME: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-analysisTime-label',
    description: '실 분석대상기간',
    defaultMessage: '실 분석대상기간',
  },
  REPORT_DOWNLOAD_STATUS_CHECK: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-data-report-download-status-label',
    description: '리포트 조회/다운로드',
    defaultMessage: '리포트 조회/다운로드',
  },
  ESTIMATED_RETURN_DATETIME: {
    id: '03-EcgTestListFragment-Table-estimatedEndDatetime-01',
    description: '2023-03-15(반납예정)',
    defaultMessage: '{endDatetime}(반납예정)',
  },
  RECORDING_TIME_DURATION: {
    id: '03-EcgTestListFragment-TableSidePanel-analysisTime-duration',
    description: '실 측정기간 x일 y시간 z분',
    defaultMessage: '{days}일 {hours}시간 {minutes}분',
  },
  ANALYSIS_TIME_DURATION: {
    id: '03-EcgTestListFragment-TableSidePanel-recordingTime-duration',
    description: '실 분석대상기간 x일 y시간 z분',
    defaultMessage: '{days}일 {hours}시간 {minutes}분',
  },
  REPORT_LAST_DOWNLOAD_PERSON_DATETIME: {
    id: '03-EcgTestListFragment-TableSidePanel-report-last-download-person-ViewedDate',
    description: '완료 : 김메모',
    defaultMessage: '완료 : {Viewer}',
  },
  REPORT_LAST_DOWNLOAD_UNDO_COMPLETION_BUTTON: {
    id: '03-EcgTestListFragment-TableSidePanel-report-last-download-undo-completion-button',
    description: '완료 해제',
    defaultMessage: '완료 해제',
  },
  REPORT_LAST_DOWNLOAD_PERSON_DATETIME_UN_VIEWED: {
    id: '03-EcgTestListFragment-TableSidePanel-report-un-viewed',
    description: '조회/다운로드하지 않음',
    defaultMessage: '조회/다운로드하지 않음',
  },
  FOOTER_BUTTON_SAVE: {
    id: '03-EcgTestListFragment-TableSidePanel-sidePanelFooter-save',
    description: '저장',
    defaultMessage: '저장',
  },
  FOOTER_BUTTON_CANCEL: {
    id: '03-EcgTestListFragment-TableSidePanel-sidePanelFooter-cancel',
    description: '취소',
    defaultMessage: '취소',
  },
  TOOLTIP_BUTTON_CLOSE: {
    id: '03-EcgTestListFragment-TableSidePanel-sidePanelHeader-button-close',
    defaultMessage: '닫기: (esc) 누르기',
    description: '닫기: (esc) 누르기',
  },
  NOTE_UPDATED_BY: {
    id: '03-EcgTestListFragment-TableSidePanel-sidePanelComment-helperText',
    description: 'Updated By : 홍길동{br}2023-05-09 (10:07)',
    defaultMessage: 'Updated By : {lastModifier}{br}{modifiedDate}',
  },
  TOOLTIP_BUTTON_BLOCKED_EDIT: {
    id: '03-EcgTestListFragment-TableSidePanel-sidePanelHeader-button-blocked-edit',
    defaultMessage: 'MEMO Cue에서 생성한 검사는 정보를 수정할 수 없습니다.',
    description: 'MEMO Cue에서 생성한 검사는 정보를 수정할 수 없습니다.',
  },
});
