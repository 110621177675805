const DATA_TEST_ID = {
  HEADER: {
    PROFILE_MENU: 'header_profile_menu',
  },
  MAIN_PAGE: {
    BUTTON: {
      ADD_NEW_TEST: 'header_add_new_test',
    },
  },
  TABLE_PAGINATION: {
    FIRST_PAGE: 'table_pagination_first_page',
    LAST_PAGE: 'table_pagination_last_page',
    NEXT_PAGE: 'table_pagination_next_page',
    PREV_PAGE: 'table_pagination_prev_page',
  },
  ADD_NEW_TEST_DIALOG: {
    PATIENT: {
      ID: 'add_new_test_dialog_patient_id',
      NAME: 'add_new_test_dialog_patient_name',
      SEX: {
        MAN: 'add_new_test_dialog_patient_sex_man',
        WOMAN: 'add_new_test_dialog_patient_sex_woman',
      },
      BIRTH_DATE: 'add_new_test_dialog_patient_birth_date',
      PHONE_NUMBER: 'add_new_test_dialog_patient_phone_number',
      PACEMAKER: {
        NOT_SELECTED: 'add_new_test_dialog_patient_pacemaker_not_selected',
        NONE: 'add_new_test_dialog_patient_pacemaker_none',
        EXIST: 'add_new_test_dialog_patient_pacemaker_exist',
      },
    },
    TEST: {
      PRESCRIPTION_DURATION: {
        CUSTOM_INPUT:
          'add_new_test_dialog_test_prescription_duration_custom_input',
        TWO_DAYS: 'add_new_test_dialog_test_prescription_duration_two_days',
        THREE_DAYS: 'add_new_test_dialog_test_prescription_duration_three_days',
        SEVEN_DAYS: 'add_new_test_dialog_test_prescription_duration_seven_days',
        EIGHT_DAYS: 'add_new_test_dialog_test_prescription_duration_eight_days',
        FOURTEEN_DAYS:
          'add_new_test_dialog_test_prescription_duration_fourteen_days',
      },
      REFERRED_BY: 'add_new_test_dialog_test_referred_by',
      NOTE: 'add_new_test_dialog_test_note',
    },
    BUTTON: {
      ADD: 'add_new_test_dialog_button_add',
      CANCEL: 'add_new_test_dialog_button_cancel',
    },
  },
  TABLE_SIDE_PANEL: {
    PATIENT: {
      NAME: 'table_side_panel_patient_name',
      NUMBER: 'table_side_panel_patient_number',
      BIRTH_DATE: 'table_side_panel_patient_birth_date',
      SEX: 'table_side_panel_patient_sex',
      PHONE_NUMBER: 'table_side_panel_patient_phone_number',
      PACEMAKER: 'table_side_panel_patient_pacemaker',
    },
    TEST: {
      REFERRED_BY: 'table_side_panel_test_referred_by',
      PRESCRIPTION_DURATION: 'table_side_panel_test_prescription_duration',
      NOTE: 'table_side_panel_test_note',
    },
    BUTTON: {
      EDIT: 'table_side_panel_button_edit',
      DELETE: 'table_side_panel_button_delete',
      CLOSE: 'table_side_panel_button_close',
      CANCEL: 'table_side_panel_button_cancel',
      SAVE: 'table_side_panel_button_save',
    },
  },
  TABLE_LIST_FILTER_HEADER: {
    SEARCH_INPUT: 'table_list_filter_header_search_input',
    ECG_TEST_STATUS_FILTER: 'table_list_filter_header_ecg_test_status_filter',
    PRESCRIPTION_FILTER: 'table_list_filter_header_prescription_filter',
    START_END_DATE_PICKER: 'table_list_filter_header_start_end_date_picker',
    BUTTON: {
      RESET: 'table_list_filter_header_button_reset',
    },
  },
  EVENT_REVIEW: {
    NAVIGATION: {
      BUTTON: {
        HEART_RATE_TREND_TOGGLE:
          'event_review_navigation_button_heart_rate_trend_toggle',
      },
      DROPDOWN: {
        THIRTY_SEC_AMPLITUDE:
          'event_review_navigation_dropdown_thirty_sec_amplitude_dropdown',
        DATE_PICKER: 'event_review_navigation_dropdown_date_picker',
      },
      INPUT: {
        TIME: 'event_review_navigation_input_time',
      },
      LEGEND: {
        LEAD_OFF: 'event_review_navigation_legend_lead_off',
        ASLEEP: 'event_review_navigation_legend_asleep',
        APC: 'event_review_navigation_legend_apc',
        VPC: 'event_review_navigation_legend_vpc',
        QUESTIONABLE: 'event_review_navigation_legend_questionable',
        AF: 'event_review_navigation_legend_af',
        PAUSE: 'event_review_navigation_legend_pause',
        AV_BLOCK: 'event_review_navigation_legend_av_block',
        OTHERS: 'event_review_navigation_legend_others',
        PATIENT: 'event_review_navigation_legend_patient',
      },
    },
    SIDE_PANEL: {
      BUTTON: {
        EVENTS: 'event_review_side_panel_button_events',
        REPORT: 'event_review_side_panel_button_report',
      },
      EVENTS_TAB: {
        AF: 'event_review_side_panel_events_tab_af',
        PAUSE: 'event_review_side_panel_events_tab_pause',
        AVB_2: 'event_review_side_panel_events_tab_avb_2',
        AVB_3: 'event_review_side_panel_events_tab_avb_3',
        VT: 'event_review_side_panel_events_tab_vt',
        ISO_VPC: 'event_review_side_panel_events_tab_iso_vpc',
        BIGEMINY_VPC: 'event_review_side_panel_events_tab_bigeminy_vpc',
        TRIGEMINY_VPC: 'event_review_side_panel_events_tab_trigeminy_vpc',
        QUADRIGEMINY_VPC: 'event_review_side_panel_events_tab_quadrigeminy_vpc',
        COUPLET_VPC: 'event_review_side_panel_events_tab_couplet_vpc',
        SVT: 'event_review_side_panel_events_tab_svt',
        ISO_APC: 'event_review_side_panel_events_tab_iso_apc',
        BIGEMINY_APC: 'event_review_side_panel_events_tab_bigeminy_apc',
        TRIGEMINY_APC: 'event_review_side_panel_events_tab_trigeminy_apc',
        QUADRIGEMINY_APC: 'event_review_side_panel_events_tab_quadrigeminy_apc',
        COUPLET_APC: 'event_review_side_panel_events_tab_couplet_apc',
        OTHERS: 'event_review_side_panel_events_tab_others',
        PATIENT: 'event_review_side_panel_events_tab_patient',
        BUTTON: {
          EXPAND_MORE: {
            ISO_VPC:
              'event_review_side_panel_events_tab_button_expend_more_iso_vpc',
            ISO_APC:
              'event_review_side_panel_events_tab_button_expend_more_iso_apc',
          },
        },
      },
      REPORT_TAB: {
        NOTABLE: 'event_review_side_panel_report_tab_notable',
        PATIENT: 'event_review_side_panel_report_tab_patient',
        AF: 'event_review_side_panel_report_tab_af',
        PAUSE: 'event_review_side_panel_report_tab_pause',
        AV_BLOCK: 'event_review_side_panel_report_tab_av_block',
        VT: 'event_review_side_panel_report_tab_vt',
        VE: 'event_review_side_panel_report_tab_ve',
        SVT: 'event_review_side_panel_report_tab_svt',
        SVE: 'event_review_side_panel_report_tab_sve',
        ADDITIONAL: 'event_review_side_panel_report_tab_additional',
      },
    },
  },
  PARCEL_DIALOG: {
    CLOSE: 'search_address_panel_button_close',
  },
} as const;

type DataTestId = typeof DATA_TEST_ID;

export { DATA_TEST_ID, type DataTestId };
