import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Const from 'constant/Const';

import { showDialog } from 'redux/duck/dialogDuck';

import { PARCEL_STATUS } from '../../dialog/ManageParcelDialog/ParcelConst';

const { ON_GOING_TEST } = Const.ECG_TEST_STATUS;
const { NONE, CANCELED } = PARCEL_STATUS;

function ReturnTypeButton(props) {
  const { ecgTest, parcel } = props;
  const { status } = parcel || {};

  const dispatch = useDispatch();

  if (!parcel) return null;
  if (ecgTest.ecgTestStatus > ON_GOING_TEST && !status) return null;

  const handleShowDialog = (dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  };

  const onClickWrapper = (evt) => {
    evt.stopPropagation();
    handleShowDialog('ManageParcelDialog', {
      ecgTest,
      parcel,
      handleShowDialog,
    });
  };

  return (
    <Wrapper status={status} onClick={onClickWrapper}>
      <Text status={status}>
        {status ? PARCEL_STATUS[status]?.text : NONE.text}
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 43px;
  height: 14px;
  padding: 3px 8px;
  border-radius: 12px;
  background-color: ${({ theme, status }) => {
    switch (status) {
      case NONE.value:
        return null;
      case CANCELED.value:
        return theme.color.COOL_GRAY_50;
      default:
        return theme.color.COOL_GRAY_80;
    }
  }};
  &:hover {
    background-color: ${({ theme, status }) => {
      switch (status) {
        case NONE.value:
          return null;
        case CANCELED.value:
          return theme.color.COOL_GRAY_60;
        default:
          return theme.color.COOL_GRAY_90;
      }
    }};
  }
`;

const Text = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 130%;
  text-decoration-line: ${({ status }) => {
    switch (status) {
      case NONE.value:
        return 'underLine';
      default:
        return 'none';
    }
  }};
  color: ${({ theme, status }) => {
    switch (status) {
      case NONE.value:
        return theme.color.COOL_GRAY_80;
      default:
        return theme.color.WHITE;
    }
  }};
  &:hover {
    color: ${({ theme, status }) => {
      switch (status) {
        case NONE.value:
          return theme.color.COOL_GRAY_90;
        default:
          return theme.color.COOL_GRAY_40;
      }
    }};
  }
`;

export default ReturnTypeButton;
