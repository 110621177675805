import { floor } from 'mathjs';

const numberFormatter = new Intl.NumberFormat('en-US');

const NumberUtil = {
  fillZero: (number, targetDigit) => {
    return (new Array(targetDigit).fill(0).join('') + number).slice(
      -targetDigit
    );
  },
  threeDigitComma(number) {
    if (!number || !isNumber(number)) {
      return number;
    }
    return numberFormatter.format(number);
    // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  phone(phoneNum) {
    return phoneNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  },
  getNumbers(str) {
    return str.replace(/\D/g, '');
  },
  getPhoneNumberFormatByPaste(param) {
    return param.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  },
  getPhoneNumberFormat(param) {
    return param
      .replace(/[^0-9]/g, '')
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
      .replace(/(\-{1,2})$/g, '');
  },
};

export default NumberUtil;

export function isNumber(value) {
  const result = typeof value === 'number' && !Number.isNaN(value);
  return result;
}

export function isPositiveNumber(value) {
  const result = isNumber(value) && value >= 0;
  return result;
}

/**
 * 숫자 값을 특정 소수점 자리수까지 표현된 문자열로 변환
 * @param {number} floatValue 변환할 숫자값
 * @param {number} decimalPlace 문자열로 표현되어야 하는 소수점 자리수
 * @returns {string}
 */
export function getStringFloat(floatValue, decimalPlace = 0) {
  if (typeof floatValue === 'number') {
    return floor(floatValue, decimalPlace).toFixed(decimalPlace);
  }

  return '-';
}

export function removeNonDigits(str) {
  return str.replace(/[^\d]/g, '');
}
/**
 *
 * console.log(isDecimal(10)); // false, 정수
 * console.log(isDecimal(10.5)); // true, 소수
 *
 * @param {*} number
 * @returns
 */
export function isDecimal(number) {
  return number % 1 !== 0;
}
