import { forwardRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { START_END_DATE_TIME } from 'constant/Const';

import {
  LabelText,
  LabelWrapper,
  StyledDropDownIcon,
  ValueText,
  ValueWrapper,
} from 'component/fragment/main/filterDropdown/EcgTestStatusFilterDropdown';

function StartEndDatePickerCustomInput({ value, type, onClick }, ref) {
  const intl = useIntl();

  return (
    <Anchor ref={ref} onClick={onClick}>
      <LabelWrapper>
        <LabelText>{_labelHandler({ value, type, intl })}</LabelText>
      </LabelWrapper>
      <ValueWrapper>
        <ValueText>{_valueHandler(value)}</ValueText>
        <StyledDropDownIcon />
      </ValueWrapper>
    </Anchor>
  );
}

// inner functions
function _valueHandler(value) {
  if (value.length === 0) return;
  const [startDate, endDate] = value.split(' - ');

  return `${startDate} ~ ${endDate}`;
}

function _labelHandler({ value, type, intl }) {
  if (value.length === 0)
    return intl.formatMessage(INTL_MAP_CUSTOM_INPUT.INQUIRY_PERIOD);

  return type === START_END_DATE_TIME.START_TIMESTAMP_MS_RANGE
    ? intl.formatMessage(INTL_MAP_CUSTOM_INPUT.START_DATETIME)
    : intl.formatMessage(INTL_MAP_CUSTOM_INPUT.ANALYSIS_END_DATE);
}

const Anchor = styled.div`
  min-width: 56px;
  max-height: 32px;
  box-sizing: border-box;
  padding: 8px;

  display: flex;
  flex-direction: row;
  align-content: stretch;
  align-items: center;
  cursor: pointer;

  background-color: ${(props) => props.theme.color.WHITE};
  border: 1px solid ${(props) => props.theme.color.COOL_GRAY_50};
  border-radius: 6px;

  &:hover {
    ${(props) =>
      !props.disabled && `background-color: ${props.theme.color.COOL_GRAY_30}`};
    transition: border-color 0.2s ease-in-out;
  }

  & * {
    pointer-events: none;
  }
`;

/**
 * - INQUIRY_PERIOD: 조회기간/
 * - START_DATETIME: 검사 시작일
 * - ANALYSIS_END_DATE : 분석 완료일
 */
export const INTL_MAP_CUSTOM_INPUT = defineMessages({
  INQUIRY_PERIOD: {
    id: '03-StartEndDatePickerCustomInput-Anchor-LabelText',
    description: '조회기간 / Inquiry period',
    defaultMessage: '조회기간',
  },
  START_DATETIME: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-startDatetime',
    description: '검사 시작일',
    defaultMessage: '검사 시작일',
  },
  ANALYSIS_END_DATE: {
    id: '03-EcgTestListFragment-SortableTableHeader-headerTitles-analysisEndDate',
    description: '분석 완료일 / Analysis End Date',
    defaultMessage: '분석 완료일',
  },
});

export default forwardRef(StartEndDatePickerCustomInput);
