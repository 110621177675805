import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { START_END_DATE_TIME } from 'constant/Const';

import DateUtil from 'util/DateUtil';

import Tooltip from 'component/ui/tooltip/Tooltip';
import Button from 'component/ui/button/Button';
import { HelpIcon } from 'component/ui/icons';
import { OnlyRadio, RadioContainer } from 'component/ui/radio/Radio';
import { INTL_MAP_CUSTOM_INPUT } from 'component/ui/picker/StartEndDate/StartEndDatePickerCustomInput';

import { ResetButton } from './TableListFilterHeaderFragment';

function CalendarContainer({
  selectedStartEndDateTime,
  totalDays,
  onTypeChange,
  clearSelectedStartEndDateTime,
  className,
  children,
  onChangeHandler,
  changeMonth,
  changeYear,
}) {
  const intl = useIntl();

  const TYPE_LIST = [
    {
      value: START_END_DATE_TIME.START_TIMESTAMP_MS_RANGE,
      label: intl.formatMessage(INTL_MAP_CUSTOM_INPUT.START_DATETIME),
      tooltip: {
        title: intl.formatMessage(INTL_MAP.SEARCH_BY_TEST_START_DATE, {
          br: <br />,
        }),
        placement: 'bottom',
        option: { maxWidth: 157 },
      },
    },
    {
      value: START_END_DATE_TIME.INTERNAL_CONFIRM_TIMESTAMP_MS_RANGE,
      label: intl.formatMessage(INTL_MAP_CUSTOM_INPUT.ANALYSIS_END_DATE),
      tooltip: {
        title: intl.formatMessage(INTL_MAP.SEARCH_BY_ANALYSIS_COMPLETION_DATE),
        placement: 'bottom',
        option: { maxWidth: 180 },
      },
    },
  ];

  const { type } = selectedStartEndDateTime;
  const [startDate, endDate] = selectedStartEndDateTime[type];
  const todayDateTime = new Date(
    DateUtil.getUserLocationTime().setHours(0, 0, 0, 0)
  );
  const thisMonthFirstDate = new Date(
    todayDateTime.getFullYear(),
    todayDateTime.getMonth(),
    1
  );
  const lastMonthFirstDate = new Date(
    todayDateTime.getFullYear(),
    todayDateTime.getMonth() - 1,
    1
  );
  const lastMonthLastDate = new Date(
    todayDateTime.getFullYear(),
    todayDateTime.getMonth(),
    0
  );

  const isToday =
    startDate?.getTime() === endDate?.getTime() &&
    startDate?.getTime() === todayDateTime?.getTime();
  const isLastMonth =
    startDate?.getTime() === lastMonthFirstDate?.getTime() &&
    endDate?.getTime() === lastMonthLastDate?.getTime();
  const isThisMonth =
    startDate?.getTime() === thisMonthFirstDate?.getTime() &&
    endDate?.getTime() === todayDateTime?.getTime();

  function onClickTodayButton() {
    onChangeHandler([todayDateTime, todayDateTime]);
    changeMonth(todayDateTime.getMonth());
    changeYear(todayDateTime.getFullYear());
  }
  function onClickLastMonthButton() {
    onChangeHandler([lastMonthFirstDate, lastMonthLastDate]);
    changeMonth(lastMonthLastDate.getMonth());
    changeYear(lastMonthLastDate.getFullYear());
  }
  function onClickThisMonthButton() {
    onChangeHandler([thisMonthFirstDate, todayDateTime]);
    changeMonth(todayDateTime.getMonth());
    changeYear(todayDateTime.getFullYear());
  }
  function onClickResetButton() {
    clearSelectedStartEndDateTime(type);
    changeMonth(todayDateTime.getMonth());
    changeYear(todayDateTime.getFullYear());
  }

  return (
    <CalendarWrapper className={className}>
      <CalendarHeader>
        {intl.formatMessage(INTL_MAP_CUSTOM_INPUT.INQUIRY_PERIOD)}
      </CalendarHeader>

      <CalendarDivider />

      <CalendarBody>
        <CalendarTypeSelector>
          <RadioContainer style={{ gap: 8 }} direction="column">
            {TYPE_LIST.map(({ label, value, tooltip }) => {
              return (
                <OnlyRadio
                  labelStyle={{ width: 84 }}
                  id={value}
                  key={value}
                  value={value}
                  label={label}
                  checked={type === value}
                  onChange={() => {
                    onTypeChange(value);
                  }}>
                  <Tooltip
                    key={value}
                    title={tooltip.title}
                    placement={tooltip.placement}
                    option={tooltip.option}>
                    <HelpIconWrapper />
                  </Tooltip>
                </OnlyRadio>
              );
            })}
          </RadioContainer>
          <ButtonWrapper>
            <Button
              color={isLastMonth ? 'primary' : 'secondary'}
              text={intl.formatMessage(INTL_MAP.LAST_MONTH)}
              outline
              onClick={onClickLastMonthButton}
            />
            <Button
              color={isThisMonth ? 'primary' : 'secondary'}
              text={intl.formatMessage(INTL_MAP.THIS_MONTH)}
              outline
              onClick={onClickThisMonthButton}
            />
            <Button
              color={isToday ? 'primary' : 'secondary'}
              text={intl.formatMessage(INTL_MAP.TODAY)}
              outline
              onClick={onClickTodayButton}
            />
          </ButtonWrapper>
        </CalendarTypeSelector>

        {children}
      </CalendarBody>

      <CalendarDivider />

      <CalendarFooter>
        <TotalDays>
          {totalDays > 0 &&
            intl.formatMessage(INTL_MAP.TOTAL_SELECTED_DAYS, { totalDays })}
        </TotalDays>
        <ResetButton onClick={onClickResetButton} />
      </CalendarFooter>
    </CalendarWrapper>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const HelpIconWrapper = styled(HelpIcon)`
  width: 12px;
  height: 12px;
  margin-left: 2px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.color.COOL_GRAY_60};
  }
`;

const CalendarWrapper = styled.div`
  width: 364px;
  padding: 6px 4px;
  box-sizing: border-box;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
`;

const CalendarHeader = styled.div`
  box-sizing: border-box;
  padding: 3px 12px;
  width: 360px;
  height: 19px;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

const CalendarBody = styled.div`
  display: flex;
`;

const CalendarFooter = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;

const CalendarTypeSelector = styled.div`
  width: 116px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CalendarDivider = styled.div`
  width: 360px;
  height: 1px;
  margin-block: 1px;
  background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
`;

const TotalDays = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const INTL_MAP = defineMessages({
  LAST_MONTH: {
    id: '03-calendarContainer-lastMonth-button-text',
    description: '저번 달 / Last Month',
    defaultMessage: '저번 달',
  },
  THIS_MONTH: {
    id: '03-calendarContainer-thisMonth-button-text',
    description: '이번 달 / This Month',
    defaultMessage: '이번 달',
  },
  TODAY: {
    id: '03-calendarContainer-today-button-text',
    description: '오늘 / Today',
    defaultMessage: '오늘',
  },
  SEARCH_BY_TEST_START_DATE: {
    id: '03-calendarContainer-startTimestampMsRange-radioButton-tooltip',
    description:
      '측정이 시작된 날짜를 기준으로 검사를 조회합니다. / Search by test start date',
    defaultMessage: '측정이 시작된 날짜를{br} 기준으로 검사를 조회합니다.',
  },
  SEARCH_BY_ANALYSIS_COMPLETION_DATE: {
    id: '03-calendarContainer-internalConfirmTimestampMsRange-radioButton-tooltip',
    description:
      '데이터 업로드 후 분석 완료된 날짜를 기준으로 검사를 조회합니다. / Search by analysis completion date',
    defaultMessage:
      '데이터 업로드 후 분석 완료된 날짜를 기준으로 검사를 조회합니다.',
  },
  TOTAL_SELECTED_DAYS: {
    id: '03-calendarContainer-TotalDays-text',
    description: '총 {totalDays}일 선택',
    defaultMessage: '총 {totalDays}일 선택',
  },
});

export default CalendarContainer;
