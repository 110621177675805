import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  border-top: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
`;

export const TableContent = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableBody = styled.tbody`
  background-color: ${(props) => props.theme.color.WHITE};
  ${(props) => {
    if (props['data-isEmpty']) return '';
    return `
    border-bottom: 1px solid ${props.theme.color.COOL_GRAY_40};
    `;
  }}
`;

export const TableHeader = styled.thead`
  background-color: ${(props) => props.theme.color.COOL_GRAY_20};
  border-bottom: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
`;

export const TableFooter = styled.tfoot``;

export const TableRowEmpty = styled.tr`
  height: ${(props) => props.height ?? 400}px;
  border-top: 1px solid #c7ced2;
`;

export const TableRowEmptyLabel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const TableRow = styled.tr`
  height: 40px;
  box-sizing: border-box;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  }

  ${(props) => {
    return `:hover {
            background-color: ${props.theme.color.ULTRA_LIGHT};
            transition: background-color 0.2s ease-in-out;
        }`;
  }}
  ${(props) => {
    if (props.disabled) {
      return `color: ${props.theme.color.MEDIUM};`;
    } else {
      return `cursor: pointer;
            :hover {
                background-color: ${props.theme.color.ULTRA_LIGHT};
            }`;
    }
  }}
  ${(props) => (props.notClickable ? 'cursor: initial;' : '')};
  && {
    background-color: ${(props) => props.selected && props.theme.color.BLUE_20};
  }

  ${({ theme, isHospitalUserReadNotification }) =>
    `background-color: ${
      isHospitalUserReadNotification === false && theme.color.SVE_20
    }`}
`;

export const TableHead = styled.th`
  z-index: 1;
  cursor: ${(props) => (props.isSortable ? 'pointer' : 'default')};

  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.color.COOL_GRAY_90};

  user-select: none;
  ${(props) =>
    props.minWidth &&
    `
        min-width: ${props.minWidth}px;
        `}
`;

export const TableData = styled.td`
  width: ${(props) => props.width || 120}px;
  max-width: ${(props) => props.maxWidth || 120}px;
  padding-left: 16px;
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${(props) => props.fontWeight || 400};
  color: ${(props) =>
    props.color ? props.theme.color[props.color] : 'inherit'};
`;
